import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { DateSpan } from 'app/project/project-list/project-list.service';
import FileSaver from 'file-saver';
import { combineLatest, first, map, Observable, Subscription } from 'rxjs';
import {
  CostCalculationMode,
  ProjectOverviewData,
} from '../project-overview/project-overview.service';
import { write, utils, WorkBook, CellStyle, RowInfo } from 'xlsx-js-style';
import { FetchProjectQuery } from '../graphql/project.generated';
import {
  FetchProjectsDataForExcelExportGQL,
  FetchProjectsDataForExcelExportQuery,
} from '../project-overview/graphql/project-overview.generated';
import { UserLocalStorageService } from 'app/shared/user';

type ProjectExcelData =
  FetchProjectsDataForExcelExportQuery['company']['projectsWithKpis']['edges'][0]['node'];

@Component({
  selector: 'app-project-overview-summary',
  templateUrl: './project-overview-summary.component.html',
  styleUrls: ['./project-overview-summary.component.scss'],
})
export class ProjectOverviewSummaryComponent implements OnInit, OnDestroy {
  @Input() public projectOverviewData: Observable<ProjectOverviewData>;
  @Input() public projectData: Observable<FetchProjectQuery['project']>;
  @Input() public setMile = true;
  @Input() public get includeOverhead(): boolean {
    return this._includeOverhead;
  }
  public set includeOverhead(value: boolean) {
    this._includeOverhead = value;
    this.initData();
  }

  @Output() public timeSpanChanged = new EventEmitter<DateSpan>();
  @Output() public includeSubProjectsChanged = new EventEmitter<boolean>();
  @Output() public overheadChanged = new EventEmitter<boolean>();
  public timeSpan: DateSpan = { startDate: null, endDate: null };

  private _includeOverhead: boolean;

  public totalExpectedRevenue: number;
  public totalAtaSum: number;
  public totalExtraSum: number;
  public totalCost: number;
  public totalHours: number;
  public totalMilage: number;

  public totalInvoiceSum: number;
  public totalInvoiceSumLeft: number;
  public currentResult: number;
  public currentCoverage: number;
  public expectedResult: number;
  public expectedCoverage: number;

  public dataSubscription: Subscription;
  public projectSubscription: Subscription;

  public excelLoading = false;

  public includeSubProjects = false;
  public showSubProjectToggle = false;

  public constructor(
    private userLocalStorage: UserLocalStorageService,
    private fetchProjectsForExcelExport: FetchProjectsDataForExcelExportGQL
  ) {}

  public ngOnInit(): void {
    this.initData();
  }

  private initData(): void {
    this.dataSubscription?.unsubscribe();
    this.dataSubscription = this.projectOverviewData.subscribe(
      (data: ProjectOverviewData) => {
        if (!data || data.kpis == null || Object.keys(data.kpis).length === 0) {
          return;
        }

        const kpis = data.kpis;

        this.totalInvoiceSum = kpis.invoicesTotalAmount;
        this.totalAtaSum = kpis.revenuesATAAcceptedAmount;
        this.totalExtraSum =
          kpis.revenuesMaterialExtraAmount +
          kpis.revenuesWorkExtraAmount +
          kpis.revenuesMilesExtraAmount;
        this.totalExpectedRevenue = kpis.revenuesTotalAmount;
        this.totalHours = kpis.hoursSum + kpis.hoursExtraSum;
        this.totalMilage = kpis.milesSum + kpis.milesExtraSum;

        this.totalInvoiceSumLeft =
          this.totalExpectedRevenue - this.totalInvoiceSum;

        if (kpis.calculationMode === CostCalculationMode.ByUser) {
          this.totalCost = kpis.costsSalaryBasedAmount;
          this.currentResult = kpis.invoicesResultsSalaryBasedAmount;
          this.currentCoverage =
            kpis.resultsCoverageInvoicedSalaryBasedPercentage / 100;
          this.expectedResult = kpis.resultsExpectedSalaryBasedAmount;
        } else {
          this.totalCost = kpis.costsUserCostTypesBasedAmount;
          this.currentResult = kpis.invoicesResultsUserCostTypesBasedAmount;
          this.currentCoverage =
            kpis.resultsCoverageInvoicedUserCostTypesBasedPercentage / 100;
          this.expectedResult = kpis.resultsExpectedUserCostTypesBasedAmount;
        }

        if (this.includeOverhead) {
          this.totalCost += kpis.costsOverheadAmount;
          this.currentResult -= kpis.costsOverheadAmount;
          this.expectedResult -= kpis.costsOverheadAmount;
        }

        this.expectedCoverage = this.expectedResult / this.totalExpectedRevenue;
        this.currentCoverage = this.currentResult / this.totalInvoiceSum;
      }
    );

    this.projectSubscription?.unsubscribe();
    this.projectSubscription = this.projectData.subscribe(data => {
      this.showSubProjectToggle = data.subProjectCount > 0;
    });
  }

  public onTimeSpanChanged(): void {
    this.timeSpanChanged.emit(this.timeSpan);
  }

  private saveAsExcelFile(buffer: BlobPart, filename: string): void {
    const EXCEL_TYPE =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    FileSaver.saveAs(
      data,
      filename + '_export_' + new Date().getTime() + EXCEL_EXTENSION
    );
  }

  private excelStyles: { [style: string]: CellStyle } = {
    bold: { font: { bold: true } },
    sectionHeaderFont: {
      font: { bold: true, sz: 16 },
    },
    bordered: {
      border: { top: { color: { rgb: '000000' }, style: 'thin' } },
    },
    number: {
      numFmt: '#,##0.00',
    },
    percentage: {
      numFmt: '0%',
    },
  };
  private excelRowStyles: { [key: string]: RowInfo } = {
    rowHeaderStyle: {
      hpx: 20,
    },
  };

  private createExcelData(
    projects: ProjectExcelData[],
    calculationMode: CostCalculationMode
  ): {
    columns: { [key: string]: string | number };
    columnStyles: { [key: string]: CellStyle };
    rowStyles: RowInfo;
  }[] {
    const isSalaryBased = calculationMode === CostCalculationMode.ByUser;

    const rows = [
      // Info
      createRow('Projekt', p => p.trueId, this.excelStyles.bold, {}),
      createRow('Märkning', p => p.mark, this.excelStyles.bold, {}),
      createRow('Kund', p => p.clientInfo, this.excelStyles.bold, {}),
      createRow(
        'Period',
        p => `${p.startDate} - ${p.endDate}`,
        this.excelStyles.bold,
        {}
      ),
      // Intäkter
      createRow(
        'Totala beräknade intäkter',
        p => Number(p.kpis.revenuesTotalAmount),
        { ...this.excelStyles.sectionHeaderFont, ...this.excelStyles.bordered },
        {
          ...this.excelStyles.sectionHeaderFont,
          ...this.excelStyles.bordered,
          ...this.excelStyles.number,
        },
        this.excelRowStyles.rowHeaderStyle
      ),
      createRow(
        'Offererat belopp',
        p => Number(p.kpis.revenuesOfferedAmount),
        this.excelStyles.bold,
        { ...this.excelStyles.bold, ...this.excelStyles.number }
      ),
      createRow(
        'Offererat ÄTA belopp',
        p => Number(p.kpis.revenuesATAAcceptedAmount),
        this.excelStyles.bold,
        { ...this.excelStyles.bold, ...this.excelStyles.number }
      ),
      createRow('Normala', () => '', this.excelStyles.bold, {}),
      createRow(
        'Timmar',
        p => Number(p.kpis.revenuesWorkAmount),
        {},
        this.excelStyles.number
      ),
      createRow(
        'Mil',
        p => Number(p.kpis.revenuesMilesAmount),
        {},
        this.excelStyles.number
      ),
      createRow(
        'Material/övrigt',
        p =>
          Number(p.kpis.revenuesProductsAmount) -
          Number(p.kpis.revenuesMaterialExtraAmount),
        this.excelStyles.bold,
        this.excelStyles.number
      ),
      createRow(
        'Summa',
        p =>
          Number(p.kpis.revenuesWorkAmount) +
          Number(p.kpis.revenuesMilesAmount) +
          Number(p.kpis.revenuesProductsAmount) -
          Number(p.kpis.revenuesMaterialExtraAmount),
        this.excelStyles.bold,
        { ...this.excelStyles.bold, ...this.excelStyles.number }
      ),
      createRow('Extra', () => '', this.excelStyles.bold, {}),
      createRow(
        'Timmar',
        p => Number(p.kpis.revenuesWorkExtraAmount),
        {},
        this.excelStyles.number
      ),
      createRow(
        'Mil',
        p => Number(p.kpis.revenuesMilesExtraAmount),
        {},
        this.excelStyles.number
      ),
      createRow(
        'Material/övrigt',
        p => Number(p.kpis.revenuesMaterialExtraAmount),
        this.excelStyles.bold,
        this.excelStyles.number
      ),
      createRow(
        'Summa',
        p =>
          Number(p.kpis.revenuesWorkExtraAmount) +
          Number(p.kpis.revenuesMilesExtraAmount) +
          Number(p.kpis.revenuesMaterialExtraAmount),
        this.excelStyles.bold,
        { ...this.excelStyles.bold, ...this.excelStyles.number }
      ),
      createRow('', () => '', {}, {}),
      // Utgifter
      createRow(
        'Totala kostnader',
        p =>
          (isSalaryBased
            ? Number(p.kpis.costsSalaryBasedAmount)
            : Number(p.kpis.costsUserCostTypesBasedAmount)) +
          (this.includeOverhead ? Number(p.kpis.costsOverheadAmount) : 0),
        { ...this.excelStyles.sectionHeaderFont, ...this.excelStyles.bordered },
        {
          ...this.excelStyles.sectionHeaderFont,
          ...this.excelStyles.bordered,
          ...this.excelStyles.number,
        },
        this.excelRowStyles.rowHeaderStyle
      ),
      createRow(
        'Timmar',
        p =>
          isSalaryBased
            ? Number(p.kpis.costsSalaryBasedWorkAmount)
            : Number(p.kpis.costsUserCostTypesBasedWorkAmount),
        {},
        this.excelStyles.number
      ),
      createRow(
        'Mil',
        p => Number(p.kpis.costsMilesAmount),
        {},
        this.excelStyles.number
      ),
      createRow(
        'Material/övrigt',
        p => Number(p.kpis.costsProductsAmount),
        this.excelStyles.bold,
        this.excelStyles.number
      ),
      this.includeOverhead
        ? createRow(
            'Omkostnader',
            p => Number(p.kpis.costsOverheadAmount),
            this.excelStyles.bold,
            this.excelStyles.number
          )
        : null,
      /* {
      a: 'Summa',
      b: costsSum,
      aStyle: this.excelStyles.bold,
      bStyle: { font: { bold: true }, numFmt: '#,##0.00' },
    }, */
      createRow('', () => '', {}, {}),
      // Fakturerat
      createRow(
        'Totalt fakturerat',
        p => Number(p.kpis.invoicesTotalAmount),
        { ...this.excelStyles.sectionHeaderFont, ...this.excelStyles.bordered },
        {
          ...this.excelStyles.sectionHeaderFont,
          ...this.excelStyles.bordered,
          ...this.excelStyles.number,
        },
        this.excelRowStyles.rowHeaderStyle
      ),
      createRow(
        'Återstående fakturering',
        p =>
          (Number(p.kpis.revenuesOfferedAmount)
            ? Number(p.kpis.revenuesOfferedAmount) +
              (Number(p.kpis.revenuesATAAcceptedAmount) ||
                Number(p.kpis.revenuesMaterialExtraAmount) +
                  Number(p.kpis.revenuesWorkExtraAmount) +
                  Number(p.kpis.revenuesMilesExtraAmount))
            : Number(p.kpis.revenuesTotalAmount)) -
          Number(p.kpis.invoicesTotalAmount),
        this.excelStyles.bold,
        this.excelStyles.number
      ),
      createRow(
        'Nuvarande resultat',
        p =>
          isSalaryBased
            ? Number(p.kpis.invoicesResultsSalaryBasedAmount)
            : Number(p.kpis.invoicesResultsUserCostTypesBasedAmount),
        this.excelStyles.bold,
        this.excelStyles.number
      ),
      createRow(
        'Förväntat resultat',
        p =>
          isSalaryBased
            ? Number(p.kpis.resultsExpectedSalaryBasedAmount)
            : Number(p.kpis.resultsExpectedUserCostTypesBasedAmount),
        this.excelStyles.bold,
        this.excelStyles.number
      ),
      createRow(
        'Nuvarande täckningsgrad',
        p =>
          (isSalaryBased
            ? Number(p.kpis.resultsCoverageInvoicedSalaryBasedPercentage)
            : Number(
                p.kpis.resultsCoverageInvoicedUserCostTypesBasedPercentage
              )) / 100,
        this.excelStyles.bold,
        this.excelStyles.percentage
      ),
      createRow(
        'Förväntad täckningsgrad',
        p =>
          (isSalaryBased
            ? Number(p.kpis.resultsCoverageExpectedOfferedSalaryBasedPercentage)
            : Number(
                p.kpis
                  .resultsCoverageExpectedOfferedUserCostTypesBasedPercentage
              )) / 100,
        this.excelStyles.bold,
        this.excelStyles.percentage
      ),
    ].filter(r => r !== null);

    return rows;

    function increaseLetter(letter: string): {
      nextLetter: string;
      shouldIncrementNextLetter: boolean;
    } {
      const nextLetter = String.fromCharCode(letter.charCodeAt(0) + 1);
      if (nextLetter > 'z') {
        return { nextLetter: 'a', shouldIncrementNextLetter: true };
      } else {
        return { nextLetter: nextLetter, shouldIncrementNextLetter: false };
      }
    }
    function getNextColumn(column: string) {
      const reverseColumnLetters = column.split('').reverse();
      const incrementedColumnLetters = [];
      let shouldIncrementLetter = true;
      for (const columnLetter of reverseColumnLetters) {
        let letterToAdd = columnLetter;
        if (shouldIncrementLetter) {
          const { nextLetter, shouldIncrementNextLetter } =
            increaseLetter(columnLetter);
          letterToAdd = nextLetter;
          shouldIncrementLetter = shouldIncrementNextLetter;
        }
        incrementedColumnLetters.push(letterToAdd);
      }
      if (shouldIncrementLetter) {
        incrementedColumnLetters.push('a');
      }
      return incrementedColumnLetters.reverse().join('');
    }

    function createRow(
      header: string,
      createValue: (project: ProjectExcelData) => string | number,
      headerStyle: CellStyle,
      valueStyle: CellStyle,
      rowStyles: RowInfo = {}
    ) {
      const row = {
        columns: {},
        columnStyles: {},
        rowStyles,
      };

      row.columns['a'] = header;
      row.columnStyles['a'] = headerStyle;

      let column = 'b';

      for (const project of projects) {
        const value = createValue(project);
        row.columns[column] = value;
        row.columnStyles[column] = valueStyle;

        column = getNextColumn(column);
      }

      // Column for summary
      row.columns[column] = '';
      row.columnStyles[column] = valueStyle;

      return row;
    }
  }

  public exportToExcel(): void {
    this.excelLoading = true;
    combineLatest([this.projectData, this.projectOverviewData])
      .pipe(first())
      .subscribe(([project, overviewData]) => {
        let projectIds = [Number(project.id)];
        if (this.includeSubProjects) {
          projectIds = [
            ...projectIds,
            ...project.subProjects.edges.map(e => Number(e.node.id)),
          ];
        }
        this.fetchProjectsForExcelExport
          .fetch({
            projectIds,
          })
          .pipe(
            first(),
            map(res =>
              res.data.company.projectsWithKpis.edges.flatMap(e => e.node)
            )
          )
          .subscribe(projects => {
            const data = this.createExcelData(
              projects,
              overviewData.kpis.calculationMode
            );
            const worksheet = utils.json_to_sheet(
              data.map(r => r.columns),
              { skipHeader: true }
            );

            const columnNames = Object.keys(data[0].columns);

            worksheet['!cols'] = columnNames.map(() => ({ wch: 40 }));
            worksheet['!rows'] = [];

            const range = utils.decode_range(worksheet['!ref']);

            const sumColumnIndex = columnNames.length - 1;
            const firstSummarizedRowIndex = 4;

            for (let i = 0; i <= range.e.r; i++) {
              for (let j = 0; j < columnNames.length - 1; j++) {
                const col = utils.encode_cell({ r: i, c: j });

                worksheet[col].s = data[i].columnStyles[columnNames[j]];
                if (data[i].rowStyles) {
                  worksheet['!rows'][i] = data[i].rowStyles;
                }
              }
              if (i >= firstSummarizedRowIndex && this.includeSubProjects) {
                const col = utils.encode_cell({ r: i, c: sumColumnIndex });
                const cell = worksheet[col];
                cell.f = `SUM(B${i + 1}:${columnNames[columnNames.length - 2]}${
                  i + 1
                })`;
                cell.s = data[i].columnStyles[columnNames[sumColumnIndex]];
              }
            }

            if (this.includeSubProjects) {
              const sumHeaderCell = utils.encode_cell({
                r: 0,
                c: sumColumnIndex,
              });
              const cell = worksheet[sumHeaderCell];
              cell.v = 'Summa';
              cell.s = this.excelStyles.bold;
            }

            const workbook: WorkBook = {
              Sheets: { data: worksheet },
              SheetNames: ['data'],
            };

            const buffer = write(workbook, {
              bookType: 'xlsx',
              type: 'array',
              cellStyles: true,
            });

            this.saveAsExcelFile(buffer, 'Projekt');
            this.excelLoading = false;
          });
      });
  }

  public ngOnDestroy(): void {
    this.dataSubscription?.unsubscribe();
    this.projectSubscription?.unsubscribe();
  }

  public onIncludeSubProjectsChanged({ checked }: { checked: boolean }) {
    this.includeSubProjectsChanged.emit(checked);
  }

  public onIncludeOverheadChanged({ checked }: { checked: boolean }) {
    this.includeOverhead = checked;
    const user = this.userLocalStorage.getMEUser();
    user.includeOverhead = String(this.includeOverhead);
    this.userLocalStorage.setMEUser(user);

    this.overheadChanged.emit(checked);
  }
}
