<div>
  <p-card [header]="'Summerat'" class="summary">
    <div class="label-value label-value-nb">
      <div>Totala beräknade intäkter</div>
      <div>{{ totalExpectedRevenue | currency: 'SEK' }}</div>
    </div>
    <div *ngIf="totalAtaSum !== 0" class="label-value label-value-sub">
      <div>varav ÄTA</div>
      <div>{{ totalAtaSum | currency: 'SEK' }}</div>
    </div>
    <div
      *ngIf="totalAtaSum === 0 && totalExtraSum !== 0"
      class="label-value label-value-sub"
    >
      <div>varav extra</div>
      <div>{{ totalExtraSum | currency: 'SEK' }}</div>
    </div>
    <div class="label-value">
      <div>Totala kostnader</div>
      <div>{{ totalCost | currency: 'SEK' }}</div>
    </div>
    <div class="label-value">
      <div>Totala timmar</div>
      <div>{{ totalHours }} h</div>
    </div>
    <div *ngIf="setMile" class="label-value">
      <div>Totala mil</div>
      <div>{{ totalMilage }} mil</div>
    </div>
  </p-card>

  <p-card class="result-progress">
    <div class="label-value">
      <div>Totalt fakturerat</div>
      <div>{{ totalInvoiceSum | currency: 'SEK' }}</div>
    </div>
    <div class="label-value">
      <div>Återstående fakturering</div>
      <div>{{ totalInvoiceSumLeft | currency: 'SEK' }}</div>
    </div>
    <div class="label-value">
      <div>Nuvarande resultat:</div>
      <div>
        {{ currentResult | currency: 'SEK' }}
        <span title="Täckningsgrad">({{ currentCoverage | percent }})</span>
      </div>
    </div>
    <div class="label-value">
      <div>Förväntat resultat:</div>
      <div>
        {{ expectedResult | currency: 'SEK' }}
        <span title="Täckningsgrad">({{ expectedCoverage | percent }})</span>
      </div>
    </div>
  </p-card>
  <p-card class="date-select">
    <div class="p-grid p-p-0">
      <span class="p-col-6 p-pb-0 p-pt-0 p-pl-0">
        <label>Från</label>
        <p-calendar
          firstDayOfWeek="1"
          dateFormat="yy-mm-dd"
          [showIcon]="true"
          [(ngModel)]="timeSpan.startDate"
          (onClose)="onTimeSpanChanged()"
          [showWeek]="true"
        ></p-calendar>
      </span>
      <span class="p-col-6 p-p-0">
        <label>Till</label>
        <p-calendar
          firstDayOfWeek="1"
          dateFormat="yy-mm-dd"
          [showIcon]="true"
          [(ngModel)]="timeSpan.endDate"
          (onClose)="onTimeSpanChanged()"
          [showWeek]="true"
        ></p-calendar>
      </span>
      <button
        pButton
        class="p-button-primary-hyperion p-col-12 p-mt-4"
        [icon]="excelLoading ? 'pi pi-spin pi-spinner' : 'pi pi-file-excel'"
        label="Exportera till Excel"
        (click)="exportToExcel()"
        [disabled]="excelLoading"
      ></button>
    </div>
  </p-card>
  <div
    class="p-d-flex p-flex-row-reverse p-jc-end p-m-3 p-p-1"
    style="gap: 0.5rem"
  >
    <span>Inkludera omkostnader</span>
    <p-inputSwitch
      [(ngModel)]="includeOverhead"
      (onChange)="onIncludeOverheadChanged($event)"
    ></p-inputSwitch>
  </div>
  <div
    class="p-d-flex p-flex-row-reverse p-jc-end p-m-3 p-p-1"
    style="gap: 0.5rem"
    *ngIf="showSubProjectToggle"
  >
    <span>Inkludera underprojekt i summeringen</span>
    <p-inputSwitch
      [(ngModel)]="includeSubProjects"
      (onChange)="onIncludeSubProjectsChanged($event)"
    ></p-inputSwitch>
  </div>
</div>
