import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ProjectCountService } from 'app/project/project-count-service/project-count-service.service';
import { MessageService } from 'app/shared/message';
import { ConfirmationService } from 'primeng/api';
import { BehaviorSubject, first, map } from 'rxjs';
import {
  AddPlanGQL,
  AddRowGQL,
  DeletePlanGQL,
  DeleteRowGQL,
  InvoiceRowTypesGQL,
  PaymentPlansGQL,
  TaxTypesGQL,
  UsersGQL,
  EditRowGQL,
  RowFragment,
  RemoveInstallmentInvoiceGQL,
  UserFragment,
  EditPlanGQL,
  PlanFragment,
} from './graphql/paymentplan.generated';

@Component({
  selector: 'app-project-payment-plan',
  templateUrl: './project-payment-plan.component.html',
  styleUrls: ['./project-payment-plan.component.scss'],
})
export class ProjectPaymentPlanComponent implements OnInit {
  public columns = [
    {
      icon: 'pi pi-wallet',
      width: '4rem',
      field: 'invoiceId',
      sortable: false,
      tooltip: 'Fakturerad',
    },
    {
      header: 'Typ',
      width: '11rem',
      field: 'typeId',
      sortable: true,
    },
    {
      header: 'Benämning',
      width: 'auto',
      field: 'benamning',
      sortable: true,
    },
    {
      header: 'ROT',
      width: '6rem',
      field: 'arbetskostnad',
      sortable: true,
    },
    {
      header: 'Antal',
      width: '7rem',
      field: 'antal',
      sortable: true,
    },
    {
      header: 'Apris',
      width: '7rem',
      field: 'apris',
      sortable: true,
    },
    {
      header: 'Moms',
      width: '7rem',
      field: 'tax',
      sortable: true,
    },
    {
      header: 'Pris (ex. moms)',
      width: '10rem',
      field: 'pris',
      sortable: true,
    },
  ];

  public projectId: number;
  public plans: BehaviorSubject<any[]> = new BehaviorSubject([]);
  public rowTypeMap: BehaviorSubject<{ [key: number]: string }> =
    new BehaviorSubject({});
  public rowTypes: BehaviorSubject<any[]> = new BehaviorSubject([]);
  public taxTypes: BehaviorSubject<any[]> = new BehaviorSubject([]);
  public users: BehaviorSubject<UserFragment[]> = new BehaviorSubject([]);

  public showCreate = false;
  public createForm: FormGroup = new FormGroup({
    name: new FormControl(),
    userId: new FormControl(),
    date: new FormControl(this.datePipe.transform(new Date(), 'yyyy-MM-dd')),
  });

  public rowCreateForms: FormGroup[] = [];
  public rowEditForms: FormGroup[][] = [];
  public planId: number;
  public totalAmount: number;

  constructor(
    private route: ActivatedRoute,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private paymentPlansService: PaymentPlansGQL,
    private invoiceRowTypesService: InvoiceRowTypesGQL,
    private taxTypesService: TaxTypesGQL,
    private usersService: UsersGQL,
    private datePipe: DatePipe,
    private addPlanService: AddPlanGQL,
    private editPlanService: EditPlanGQL,
    private deletePlanService: DeletePlanGQL,
    private addRowService: AddRowGQL,
    private deleteRowService: DeleteRowGQL,
    private editRowService: EditRowGQL,
    private removeInvoiceRowService: RemoveInstallmentInvoiceGQL,
    private projectCountService: ProjectCountService
  ) {}

  public ngOnInit(): void {
    this.initData();
    this.getRowTypes();
    this.getTaxTypes();
    this.getUsers();
  }

  private getPaymentPlans(): void {
    this.paymentPlansService
      .fetch({ projectId: this.projectId })
      .pipe(
        first(),
        map(r => r.data.project.installments.edges.map(e => e.node))
      )
      .subscribe(res => {
        this.projectCountService.count(this.projectId);
        const plans = res.map(n => ({
          ...n,
          rows: n.rows.edges.map(e => e.node),
        }));
        this.plans.next(plans);
        this.rowEditForms = [];
        plans.forEach(p => {
          this.rowCreateForms.push(this.createRowFormGroup());
          const editForms = [];
          p.rows.forEach(r => editForms.push(this.editRowFormGroup(r)));
          this.rowEditForms.push(editForms);

          this.rowEditForms.push();
        });
        this.totalAmount = plans.reduce(
          (a, b) =>
            a +
            b.rows.reduce((a, b) => a + Number(b.apris) * Number(b.antal), 0),
          0
        );
      });
  }

  private getRowTypes(): void {
    this.invoiceRowTypesService
      .fetch()
      .pipe(first())
      .subscribe(res => {
        const rowTypeMap: { [key: number]: string } = {};
        const rowTypes =
          res.data.company.generalData_PreCompanyTypeHyperion.invoiceRowTypeList.edges.map(
            e => e.node
          );
        rowTypes.forEach(e => (rowTypeMap[e.key] = e.description));
        this.rowTypes.next(rowTypes);
        this.rowTypeMap.next(rowTypeMap);
      });
  }

  private getTaxTypes(): void {
    this.taxTypesService
      .fetch()
      .pipe(first())
      .subscribe(res => {
        const taxTypes =
          res.data.company.generalData_PreCompanyTypeHyperion.invoiceTaxList.edges.map(
            e => e.node
          );
        this.taxTypes.next(taxTypes);
      });
  }

  private getUsers(): void {
    this.usersService
      .fetch()
      .pipe(first())
      .subscribe(res =>
        this.users.next(
          res.data.company.users.edges
            .map(e => e.node)
            .filter(u => !u.isDeleted && !u.hidden && u.allowed)
        )
      );
  }

  private initData(): void {
    this.route.parent.params.subscribe(p => {
      this.projectId = p.id;
      this.getPaymentPlans();
    });
  }

  public addPlan(): void {
    this.addPlanService
      .mutate({
        projectId: Number(this.projectId),
        name: this.createForm.controls.name.value,
        userId: Number(this.createForm.controls.userId.value),
        date: this.createForm.controls.date.value,
      })
      .pipe(first())
      .subscribe(res => {
        const success =
          res.data.projectInstallmentTypeHyperionMutation.mutationDetails.every(
            d => d.mutationSucceeded
          );
        this.messageService.insertDataFromMutation(
          res.data.projectInstallmentTypeHyperionMutation
        );
        this.showCreate = !success;
        this.getPaymentPlans();
      });
  }

  public deletePlan(id: string): void {
    this.confirmationService.confirm({
      header: 'Radera Plan?',
      message: 'Säker på att du vill radera betalningsplanen?',
      accept: () => {
        this.deletePlanService.mutate({ id: Number(id) }).subscribe(res => {
          this.messageService.insertDataFromMutation(
            res.data.projectInstallmentTypeHyperionMutation
          );
          const success =
            res.data.projectInstallmentTypeHyperionMutation.mutationDetails.every(
              d => d.mutationSucceeded
            );
          if (success) {
            this.getPaymentPlans();
          }
        });
      },
    });
  }

  public editPlan(): void {
    this.editPlanService
      .mutate({
        id: this.planId,
        name: this.createForm.controls.name.value,
        userId: Number(this.createForm.controls.userId.value),
        date: this.createForm.controls.date.value,
      })
      .pipe(first())
      .subscribe(res => {
        this.messageService.insertDataFromMutation(
          res.data.projectInstallmentTypeHyperionMutation
        );
        const success =
          res.data.projectInstallmentTypeHyperionMutation.mutationDetails.every(
            d => d.mutationSucceeded
          );
        if (success) {
          this.getPaymentPlans();
        }
        this.showCreate = !success;
      });
  }

  private createRowFormGroup(): FormGroup {
    return new FormGroup({
      typeId: new FormControl(0),
      benamning: new FormControl('', [Validators.required]),
      arbetskostnad: new FormControl(false),
      antal: new FormControl(),
      apris: new FormControl(),
      pris: new FormControl(),
      tax: new FormControl(25),
    });
  }

  public editRowFormGroup(row: RowFragment): FormGroup {
    return new FormGroup({
      invoiced: new FormControl(Boolean(row.invoiceId)),
      typeId: new FormControl(row.typeId),
      benamning: new FormControl(row.benamning, [Validators.required]),
      arbetskostnad: new FormControl(),
      antal: new FormControl(row.antal ?? 0),
      apris: new FormControl(row.apris ?? 0),
      pris: new FormControl(row.pris ?? 0),
      tax: new FormControl(row.tax),
    });
  }

  public createRow(rowControl: FormGroup, planId: number): void {
    rowControl.markAllAsTouched();
    if (rowControl.status !== 'VALID') {
      return;
    }
    this.addRowService
      .mutate({
        planId: planId,
        typeId: rowControl.controls.typeId.value,
        antal: rowControl.controls.antal.value,
        apris: rowControl.controls.apris.value,
        arbetskostnad: rowControl.controls.arbetskostnad.value,
        benamning: rowControl.controls.benamning.value,
        pris: String(
          rowControl.controls.apris.value * rowControl.controls.antal.value
        ),
        tax: rowControl.controls.tax.value,
      })
      .pipe(first())
      .subscribe(res => {
        this.messageService.insertDataFromMutation(
          res.data.projectInstallmentRowTypeHyperionMutation
        );

        this.getPaymentPlans();
        rowControl.patchValue({
          typeId: 0,
          benamning: '',
          arbetskostnad: false,
          tax: 25,
        });
        rowControl.markAsUntouched();
        rowControl.markAsPristine();
      });
  }

  public deleteRow(rowId: string): void {
    this.confirmationService.confirm({
      header: 'Radera rad?',
      message: 'Säker på att du vill radera raden?',
      accept: () => {
        this.deleteRowService.mutate({ id: Number(rowId) }).subscribe(_ => {
          this.getPaymentPlans();
        });
      },
    });
  }

  public editRow(rowForm: FormGroup, rowId: string): void {
    rowForm.markAllAsTouched();
    if (rowForm.status !== 'VALID') {
      return;
    }

    this.editRowService
      .mutate({
        id: Number(rowId),
        typeId: rowForm.controls.typeId.value,
        antal: rowForm.controls.antal.value,
        apris: rowForm.controls.apris.value,
        arbetskostnad: Number(rowForm.controls.arbetskostnad.value),
        benamning: rowForm.controls.benamning.value,
        pris: String(
          rowForm.controls.apris.value * rowForm.controls.antal.value
        ),
        tax: rowForm.controls.tax.value,
      })
      .subscribe(res => {
        if (!rowForm.controls.invoiced.value) {
          this.removeInvoiced(rowId);
        } else {
          this.messageService.insertDataFromMutation(
            res.data.projectInstallmentRowTypeHyperionMutation
          );
          this.getPaymentPlans();
        }
      });
  }

  private removeInvoiced(id: string): void {
    this.removeInvoiceRowService.mutate({ id: Number(id) }).subscribe(res => {
      this.messageService.insertDataFromMutation(
        res.data.projectInstallmentRowTypeHyperionMutation
      );
      this.getPaymentPlans();
    });
  }

  public convertToNumber(s: string): number {
    return Number(s);
  }

  public actionEditPlan(plan: PlanFragment): void {
    this.planId = Number(plan.id);
    this.createForm.patchValue({
      name: plan.name,
      userId: plan.user.id,
      date: plan.dateToInvoice,
    });
    this.showCreate = true;
  }

  public actionCreatePlan(): void {
    this.planId = null;
    this.createForm.reset();
    this.showCreate = true;
  }
  public createPdf() {
    const url = '/project/printPaymentPlan/' + this.projectId;
    window.open(url);
  }

  public actionDownloadPDF(): void {
    this.createPdf();
    return;
  }
}
