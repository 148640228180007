import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HeaderModule } from 'app/header/header.module';
import { AccordionModule } from 'primeng/accordion';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { DividerModule } from 'primeng/divider';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { HyperionSharedModule } from 'app/shared/shared.module';
import { SidebarModule } from 'primeng/sidebar';
import { ContactModule } from '../contact/contact.module';
import { InputSwitchModule } from 'primeng/inputswitch';
import { TableModule } from 'primeng/table';
import { CheckboxModule } from 'primeng/checkbox';
import { MenuModule } from 'primeng/menu';
import { SpinnerModule } from 'primeng/spinner';
import { ButtonModule } from 'primeng/button';
import { ImageModule } from 'primeng/image';
import { InplaceModule } from 'primeng/inplace';
import { SplitButtonModule } from 'primeng/splitbutton';

import { ProjectExternalModule } from 'app/shared/company/derome-integration/project-external.module';
import { OfferModule } from 'app/offer/offer.module';
import { TabMenuModule } from 'primeng/tabmenu';

import { ProjectFormComponent } from './project-form/project-form.component';
import { ProjectTypeFormComponent } from './project-type-form/project-type-form.component';

import { ProjectListComponent } from './project-list/project-list.component';
import { ProjectExtendedSearchComponent } from './project-list/project-extended-search/project-extended-search.component';

import { ProjectDetailComponent } from './project-detail/project-detail.component';
import { ProjectCommentsComponent } from './project-detail/project-comments/project-comments.component';
import { ProjectInfoComponent } from './project-detail/project-info/project-info.component';
import { ProjectInfoViewComponent } from './project-detail/project-info/project-info-view/project-info-view.component';
import { ProjectOverviewCostsComponent } from './project-detail/project-info/project-overview-costs/project-overview-costs.component';
import { ProjectOverviewLaborComponent } from './project-detail/project-info/project-overview-labor/project-overview-labor.component';
import { ProjectOverviewMilesComponent } from './project-detail/project-info/project-overview-miles/project-overview-miles.component';
import { ProjectOverviewMaterialComponent } from './project-detail/project-info/project-overview-material/project-overview-material.component';
import { ProjectWorkMaterialComponent } from './project-detail/project-info/project-work-material/project-work-material.component';
import { ProjectMaterialComponent } from './project-detail/project-info/project-work-material/project-material/project-material.component';
import { ProjectWorkPerformedComponent } from './project-detail/project-info/project-work-material/project-work-performed/project-work-performed.component';
import { ProjectEconomyComponent } from './project-detail/project-info/project-economy/project-economy.component';
import { ProjectAcontoComponent } from './project-detail/project-info/project-economy/project-aconto/project-aconto.component';
import { ProjectCustomerPriceComponent } from './project-detail/project-info/project-economy/project-customer-price/project-customer-price.component';
import { ProjectDocumentsComponent } from './project-detail/project-documents/project-documents.component';
import { ProjectImagesComponent } from './project-detail/project-images/project-images.component';
import { ProjectTodoComponent } from './project-detail/project-todo/project-todo.component';
import { ProjectTodoSubtableComponent } from './project-detail/project-todo-subtable/project-todo-subtable.component';
import { ProjectTodoFormComponent } from './project-detail/project-todo-form/project-todo-form.component';
import { ProjectPaymentPlanComponent } from './project-detail/project-payment-plan/project-payment-plan.component';
import { DayModule } from 'app/day/day.module';
import { ProjectAttendanceComponent } from './project-detail/project-attendance/project-attendance.component';
import { ProjectAttendanceTableComponent } from './project-detail/project-attendance/project-attendance-table/project-attendance-table.component';
import { ProjectRotComponent } from './project-detail/project-rot/project-rot.component';
import { ProjectAtaComponent } from './project-detail/project-ata/project-ata.component';
import { ProjectShareComponent } from './project-detail/project-share/project-share.component';
import { ProjectSubcontractorSearchComponent } from './project-detail/project-subcontractor-search/project-subcontractor-search.component';
import { ProjectSubcontractorFormComponent } from './project-detail/project-subcontractor-form/project-subcontractor-form.component';
import { ProjectSupplierInvoiceLegacyComponent } from './project-detail/project-supplier-invoice/project-supplier-invoice.component';
import { SupplierInvoiceRowHolderLegacyComponent } from './project-detail/project-supplier-invoice/supplier-invoice-row-holder.component';
import { ProjectOverviewSummaryComponent } from './project-detail/project-info/project-overview-summary/project-overview-summary.component';
import { ProjectOverviewComponent } from './project-detail/project-info/project-overview/project-overview.component';
import { ProjectOverviewService } from './project-detail/project-info/project-overview/project-overview.service';
import { ProjectOverviewDiagramComponent } from './project-detail/project-info/project-overview-diagram/project-overview-diagram.component';
import { ProjectStatusIconComponent } from './project-status-icon/project-status-icon.component';
import { ProjectParameterSettingsComponent } from './project-detail/project-info/project-parameter-settings/project-parameter-settings.component';
import { SharedModule } from 'primeng/api';
import { ProjectListService } from './project-list/project-list.service';
import { ProjectSubprojectListComponent } from './project-subproject-list/project-subproject-list.component';
import { TreeTableModule } from 'primeng/treetable';
import { ProjectWorkPerformedLazyComponent } from './project-detail/project-info/project-work-material/project-work-performed/project-work-performed-lazy.component';
import { AngleRightIcon } from 'primeng/icons/angleright';
import { AngleDoubleRightIcon } from 'primeng/icons/angledoubleright';
import { AngleLeftIcon } from 'primeng/icons/angleleft';
import { AngleDoubleLeftIcon } from 'primeng/icons/angledoubleleft';

@NgModule({
  declarations: [
    ProjectInfoComponent,
    ProjectInfoViewComponent,
    ProjectFormComponent,
    ProjectTypeFormComponent,
    ProjectDetailComponent,
    ProjectListComponent,
    ProjectEconomyComponent,
    ProjectAcontoComponent,
    ProjectCustomerPriceComponent,
    ProjectExtendedSearchComponent,
    ProjectWorkPerformedComponent,
    ProjectWorkPerformedLazyComponent,
    ProjectWorkMaterialComponent,
    ProjectOverviewCostsComponent,
    ProjectOverviewLaborComponent,
    ProjectOverviewMilesComponent,
    ProjectOverviewMaterialComponent,
    ProjectMaterialComponent,
    ProjectCommentsComponent,
    ProjectDocumentsComponent,
    ProjectImagesComponent,
    ProjectTodoComponent,
    ProjectTodoSubtableComponent,
    ProjectTodoFormComponent,
    ProjectPaymentPlanComponent,
    ProjectAttendanceComponent,
    ProjectAttendanceTableComponent,
    ProjectRotComponent,
    ProjectAtaComponent,
    ProjectShareComponent,
    ProjectSubcontractorSearchComponent,
    ProjectSubcontractorFormComponent,
    ProjectSupplierInvoiceLegacyComponent,
    SupplierInvoiceRowHolderLegacyComponent,
    ProjectOverviewSummaryComponent,
    ProjectOverviewComponent,
    ProjectOverviewDiagramComponent,
    ProjectStatusIconComponent,
    ProjectParameterSettingsComponent,
    ProjectSubprojectListComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    HeaderModule,
    AccordionModule,
    InputTextModule,
    DropdownModule,
    AutoCompleteModule,
    DividerModule,
    CalendarModule,
    HyperionSharedModule,
    CardModule,
    SidebarModule,
    ContactModule,
    InputSwitchModule,
    TableModule,
    TreeTableModule,
    CheckboxModule,
    ButtonModule,
    MenuModule,
    SpinnerModule,
    ImageModule,
    InplaceModule,
    DayModule,
    ProjectExternalModule,
    OfferModule,
    TabMenuModule,
    SplitButtonModule,
    SharedModule,
    AngleRightIcon,
    AngleDoubleRightIcon,
    AngleLeftIcon,
    AngleDoubleLeftIcon,
  ],
  exports: [ProjectFormComponent],
  providers: [ProjectOverviewService, ProjectListService],
})
export class ProjectModule {}
