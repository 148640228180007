import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import PROJECT_STATUS from 'app/shared/global/project-status.enum';
import moment from 'moment';
import { first, map, Observable } from 'rxjs';
import { FetchProjectIdsForIncomeExportGQL } from './graphql/projects.generated';
import { Column, KpiColumns } from './project-list.component';

@Injectable()
export class ProjectListService {
  public userSortSetting: {
    object: string;
    attribute: string;
    ascDesc: number;
  };
  public statusMap = {};
  public extendedSearchOptions = {};
  public typeIds: number[] = [];
  public createdByIds: number[] = [];
  public search: string = null;

  public dateSpan: DateSpan = {
    startDate: moment().dayOfYear(1).toDate(),
    endDate: moment().dayOfYear(365).toDate(),
  };

  public constructor(
    private apollo: Apollo,
    private fetchProjectIdsForIncomeExportService: FetchProjectIdsForIncomeExportGQL
  ) {}

  /**
   * Manual query to get projects with KPIs so we can select KPIs dynamically
   */
  public fetchProjectsWithKPIs(
    infoColumns: Column[],
    kpiColumns: KpiColumns[],
    filters: object = {},
    queryOptions: any = {}
  ): Observable<any> {
    const propertiesQuery = infoColumns
      .filter(i => i.active)
      .map(i => i.field)
      .join('\n');

    const kpiQuery = kpiColumns
      .flatMap(c => c.items)
      .filter(i => i.active)
      .map(i => i.field.replace('kpis.', ''))
      .join('\n');

    const filterEntries = Object.entries(filters)
      .map(
        ([filterProperty, searchTerm]) =>
          [filterProperty, String(searchTerm)] as [string, string]
      )
      .filter(([_, searchTerm]) => searchTerm !== '');

    const shouldSort = [
      ...infoColumns,
      ...kpiColumns.flatMap(c => c.items),
    ].find(c => c.field.includes(this.userSortSetting.attribute)).active;

    const filterParams = filterEntries.map(f => `$${f[0]}: String`).join('\n');
    const filterVars = filterEntries.map(f => `${f[0]}: $${f[0]}`).join('\n');
    const filterVals = Object.fromEntries(filterEntries);

    const options = {
      offset: 0,
      limit: null,
      fromDate: this.dateSpan.startDate.toLocaleDateString(`sv-SE`),
      toDate: this.dateSpan.endDate.toLocaleDateString(`sv-SE`),
      status: this.getStatusList(),
      sort: shouldSort
        ? `${this.userSortSetting.attribute} ${
            this.userSortSetting.ascDesc > 0 ? 'asc' : 'desc'
          }`
        : null,
      search: this.search,
      typeIds: this.typeIds,
      createdByIds: this.createdByIds,
      ...queryOptions,
      ...filterVals,
    };
    const summaryQuery = kpiQuery.length
      ? `
        projectsWithKpisSummary(
          fromDate: $fromDate
          toDate: $toDate
          status: $status
          search: $search
          typeIds: $typeIds
          createdByIds: $createdByIds
          ${filterVars}
        ) {
          kpis {
            ${kpiQuery}
          }
        }`
      : '';
    const query = gql`
        query getProjectsWithKpis(
          $fromDate: DateTime
          $toDate: DateTime
          $status: [Int]
          $sort: String
          $offset: Int
          $limit: Int
          $search: String
          $typeIds: [Int]
          $createdByIds: [Int]
          ${filterParams}
        ) {
          company {
            projectsWithKpis(
              fromDate: $fromDate
              toDate: $toDate
              status: $status
              sort: $sort
              offset: $offset
              limit: $limit
              search: $search
              typeIds: $typeIds
              createdByIds: $createdByIds
              ${filterVars}
            ) {
              totalCount
              edges {
                node {
                  id
                  ${propertiesQuery}
                  startDate
                  endDate
                  kpis {
                    todosCount
                    todosDoneCount
                    todosNotDoneCount
                    invoicesDaysNotInvoicedCount
                    invoicesSupplierInvoiceRowsNotMovedToCostsCount
                    invoicesSupplierInvoiceRowsCount
                    ${kpiQuery}
                  }
                }
              }
            }
            ${summaryQuery}
          }
        }
      `;
    return this.apollo.query({ query, variables: options });
  }

  public getStatusList(statusMap?): PROJECT_STATUS[] {
    statusMap ??= this.statusMap;
    return [
      PROJECT_STATUS.FINISHED,
      PROJECT_STATUS.ARCHIVED,
      PROJECT_STATUS.ONGOING,
      PROJECT_STATUS.PLANNED,
      PROJECT_STATUS.INHOUSE,
      PROJECT_STATUS.LEAVE,
    ].filter(s => statusMap[s]);
  }

  public fetchIdsForExport(filters: object): Observable<number[]> {
    return this.fetchProjectIdsForIncomeExportService
      .fetch({
        fromDate: this.dateSpan.startDate.toLocaleDateString(`sv-SE`),
        toDate: this.dateSpan.endDate.toLocaleDateString(`sv-SE`),
        status: this.getStatusList(),
        typeIds: this.typeIds,
        search: this.search,
        ...filters,
      })
      .pipe(
        first(),
        map(res => res.data.company.projectsWithKpis.edges.map(e => e.node.id))
      );
  }
}

export type DateSpan = {
  startDate: Date;
  endDate: Date;
};
