import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { emailValidator } from 'app/shared/forms/validators';
import { GlobalService } from 'app/shared/global';
import { MessageService } from 'app/shared/message';
import { ConfirmationService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { first } from 'rxjs';
import { ApolloError } from '@apollo/client/core';
import {
  OfferInfoGQL,
  SendToZignedGQL,
} from './graphql/sendToZigned.generated';

@Component({
  selector: 'app-offer-sign-dialog',
  templateUrl: './offer-sign-dialog.component.html',
  styleUrls: ['./offer-sign-dialog.component.scss'],
})
export class OfferSignDialogComponent implements OnInit {
  @Input()
  public offerId: string;
  public emailFormControl = new FormControl('', [emailValidator()]);
  public isSending = false;
  public pdfUrl: SafeUrl;
  public trueId: string;

  constructor(
    private sendToZignedGQL: SendToZignedGQL,
    private offerInfoGQL: OfferInfoGQL,
    private messageService: MessageService,
    private dialogRef: DynamicDialogRef,
    private dialogConfig: DynamicDialogConfig,
    private globalService: GlobalService,
    private domSanitizer: DomSanitizer,
    private confirmationsService: ConfirmationService
  ) {}

  public ngOnInit(): void {
    this.offerId = this.dialogConfig.data.offerId;
    this.pdfUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(
      this.globalService.getUrlPrefix() +
        '/offer/Print/type/showPDF/id/' +
        this.offerId
    );

    this.offerInfoGQL
      .fetch({ id: Number(this.offerId) })
      .pipe(first())
      .subscribe({
        next: res => {
          this.trueId = res.data.offer.trueId;
          this.emailFormControl.setValue(res.data.offer.clientContact.mail);
        },
      });
  }

  public actionSend(): void {
    this.confirmationsService.confirm({
      header: 'Skicka offert?',
      message: `
Vill du skicka offert ${this.trueId} för digital signering till <b>${this.emailFormControl.value}</b>?
<br>
<br>
Genom att klicka på Bekräfta godkänner du kostnaden på 20 kr för digital signering.
<br>
<br>
<b>Detta debiteras även om kunden inte accepterar offerter.</b>
`,
      accept: () => this.send(),
    });
  }

  private send(): void {
    this.isSending = true;
    this.sendToZignedGQL
      .mutate({
        offerId: Number(this.offerId),
        email: this.emailFormControl.value,
      })
      .pipe(first())
      .subscribe({
        next: res => {
          this.isSending = false;
          this.messageService.insertDataFromMutation(
            res.data.sendOfferToZignedMutation
          );
          this.dialogRef.close();
        },
        error: (error: ApolloError) => {
          this.isSending = false;
          this.messageService.insertData({
            textArray: [error.message],
            type: 'error',
          });
        },
      });
  }
}
