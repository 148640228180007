<app-header>
  <button
    pButton
    *ngIf="meUser.type === '3'"
    (click)="openSettingsDialog()"
    icon="pi pi-cog"
    [disabled]="isLoading | async"
  ></button>

  <button
    pButton
    label="Skapa tidrapport"
    icon="pi pi-plus"
    class="p-button-primary-hyperion"
    (click)="openDayHandleDialog()"
    [disabled]="isLoading | async"
  ></button>
</app-header>

<div class="p-p-4 p-grid p-ai-center">
  <div class="p-sm-12 p-sm-12 p-md-3 p-lg-3">
    <label class="p-text-bold">Från</label>
    <p-calendar
      firstDayOfWeek="1"
      dateFormat="yy-mm-dd"
      dataType="string"
      [showIcon]="true"
      [(ngModel)]="dateRange.from"
      (ngModelChange)="loadTimeReports()"
      [showWeek]="true"
    ></p-calendar>
  </div>

  <div class="p-sm-12 p-sm-12 p-md-3 p-lg-3">
    <label class="p-text-bold">Till</label>
    <p-calendar
      firstDayOfWeek="1"
      dateFormat="yy-mm-dd"
      dataType="string"
      [showIcon]="true"
      [(ngModel)]="dateRange.to"
      (ngModelChange)="loadTimeReports()"
      [showWeek]="true"
    ></p-calendar>
  </div>

  <div
    class="p-sm-12 p-md-6 p-lg-3"
    *ngIf="
      meUser.type === '3' ||
      (meUser.type === '2' &&
        functionsThisModel['advancedUserCanNotarizeTimereports'])
    "
    tooltipPosition="top"
    pTooltip="Välj person(er)"
  >
    <label class="p-text-bold">Välj person(er)</label>
    <p-multiSelect
      id="usersDropdown"
      [options]="usersDropdown"
      [(ngModel)]="users"
      defaultLabel="Välj person(er)"
      (onPanelHide)="loadTimeReports()"
      [disabled]="isLoading | async"
      selectedItemsLabel="{0} valda personer"
      [overlayOptions]="{ appendTo: 'body' }"
    >
    </p-multiSelect>
  </div>
  <div
    class="p-sm-12 p-md-6 p-lg-3"
    *ngIf="
      !(
        meUser.type === '3' ||
        (meUser.type === '2' &&
          functionsThisModel['advancedUserCanNotarizeTimereports'])
      )
    "
  ></div>

  <div class="p-lg-3 p-md-3 align-right p-g-nopad">
    <button
      pButton
      (click)="showSettings = !showSettings"
      icon="pi pi-search-plus"
      label="Utökad sökning"
    ></button>
  </div>

  <ng-container *ngIf="showSettings">
    <div class="p-sm-12 p-md-3">
      <label class="p-mr-2 p-text-bold">Välj projekt</label>
      <p-multiSelect
        id="projectDropdown"
        defaultLabel="Välj projekt"
        selectedItemsLabel="{0} valda projekt"
        [options]="projectDropdown"
        [(ngModel)]="projects"
        (onPanelHide)="loadTimeReports()"
        [disabled]="isLoading | async"
        [maxSelectedLabels]="100"
        [overlayOptions]="{ appendTo: 'body' }"
      ></p-multiSelect>
    </div>

    <div class="p-sm-12 p-md-3">
      <label class="p-mr-2 p-text-bold">Gruppera</label>
      <p-dropdown
        [disabled]="isLoading | async"
        [(ngModel)]="rowGroupModel"
        (onChange)="loadTimeReports()"
        [options]="groupingDropdown"
      ></p-dropdown>
    </div>
    <div *ngIf="meUser.type === '3'" class="p-sm-12 p-md-3">
      <label class="p-mr-2 p-text-bold">Raderade Användare</label>
      <p-multiSelect
        id="deletedUsersDropdown"
        [options]="deletedUsersDropdown"
        [(ngModel)]="deletedUsers"
        defaultLabel="Välj person(er)"
        (onPanelHide)="loadTimeReports()"
        [disabled]="isLoading | async"
        selectedItemsLabel="{0} valda personer"
        [overlayOptions]="{ appendTo: 'body' }"
      >
      </p-multiSelect>
    </div>
    <div *ngIf="meUser.type !== '3'" class="p-sm-12 p-md-3"></div>
    <div class="p-sm-12 p-md-3"></div>
  </ng-container>

  <h4 class="p-xl-3 p-col-12">
    Rapporterade tider
    <span
      class="help-tooltip"
      tooltipPosition="right"
      pTooltip="Nedan visas skapade tidrapporter för gjorda val ovan. Du kan utöka dina val under 'visa fler val'"
    >
      ?
    </span>
  </h4>

  <div class="p-xl-6 p-col-12 p-d-flex p-jc-start p-jc-xl-center">
    <p-button
      label="Hämta tidsstatistik"
      icon="pi pi-file-excel"
      (click)="getStatisticsReport()"
      class="p-mr-2"
    ></p-button>

    <p-button
      label="Rapportgenerator"
      icon="pi pi-file"
      (click)="openDayReportDialog()"
      class="p-mr-2"
      [disabled]="this.isReportDisabled"
    >
    </p-button>

    <p-button
      *ngIf="!this.splitLeaveDays"
      label="Bryt ut frånvaro"
      (click)="this.splitLeaveDays = true"
    ></p-button>

    <p-button
      label="Bryt ej ut frånvaro"
      *ngIf="this.splitLeaveDays"
      (click)="this.splitLeaveDays = false"
    ></p-button>
  </div>

  <div class="p-xl-3 p-col-12 p-d-flex p-jc-end">
    <p-button
      label="Visa produkter"
      (click)="toggleSection(PROJECT_TYPE.PRODUCTS)"
      class="p-mr-2"
    ></p-button>
    <p-button
      label="Visa kommentarer"
      (click)="toggleSection(PROJECT_TYPE.COMMENTS)"
    ></p-button>
  </div>

  <div class="p-col-12" [style.overflow-x]="'auto'">
    <spinner *ngIf="isLoading | async"></spinner>

    <p-treeTable
      *ngFor="
        let days of splitLeaveDays ? [daysNodes, leaveDaysNodes] : [daysNodes];
        let i = index
      "
      [value]="days | async"
      sortField="date"
    >
      <ng-template pTemplate="header">
        <tr>
          <th *ngFor="let col of columns" [style.width]="col.width">
            <span class="p-d-flex p-ai-center p-jc-between">
              {{ col.header }}
              <button
                pButton
                *ngIf="col.field === 'date'"
                class="p-button-text legacy-rounded-button"
                [style]="{
                  transform: allExpanded ? 'rotate(90deg)' : 'rotate(0deg)'
                }"
                icon="pi pi-angle-double-right"
                (click)="actionToggleExpandAll()"
              ></button>
            </span>
          </th>
          <th [style.width]="'7rem'"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
        <tr *ngIf="rowData.id === 'sum'" [ttRow]="rowNode">
          <td
            [colSpan]="['projectId', 'userId'].includes(rowGroupModel) ? 3 : 4"
            style="white-space: pre-line; position: relative"
          >
            <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
            {{ rowData.date }}
          </td>
          <ng-container *ngFor="let col of columns">
            <td
              *ngIf="
                !['project', 'user', 'doneWork', 'date'].includes(col.field)
              "
              style="white-space: pre-line; position: relative"
              class="p-text-bold"
            >
              {{ rowData[col.field] }}
            </td>
          </ng-container>
          <td></td>
        </tr>
        <tr *ngIf="rowData.id !== 'sum'" [ttRow]="rowNode">
          <td
            *ngFor="let col of columns"
            style="white-space: pre-line; position: relative"
          >
            <span *ngIf="col.field === 'project'">
              {{ rowData.project?.trueId + ', ' + rowData.project?.mark }}
            </span>
            <span *ngIf="col.field === 'user'">
              {{ rowData.user?.firstName + ' ' + rowData.user?.lastName }}
            </span>
            <span *ngIf="col.field === 'extra'">
              {{ rowData.extra === 1 ? 'Ja' : 'Nej' }}
            </span>
            <span *ngIf="col.field === 'overtimeHardCoded'">
              {{ attendanceTypes[rowData.overtimeHardCoded] }}
            </span>

            <span *ngIf="col.field === 'costType'">
              {{ userCostTypes[rowData.costTypeHyperion?.companyCostTypeId] }}
            </span>
            <span
              *ngIf="
                !['project', 'user', 'extra', 'overtimeHardCoded'].includes(
                  col.field
                )
              "
            >
              {{ rowData[col.field] }}
            </span>
          </td>
          <td>
            <span class="p-d-flex p-jc-between">
              <button
                pButton
                class="p-button-text legacy-rounded-button"
                (click)="actionEdit(rowData)"
                icon="pi pi-pencil"
              ></button>
              <button
                pButton
                class="p-button-text legacy-rounded-button p-button-danger-inverted"
                (click)="actionDelete(rowData.id)"
                icon="pi pi-trash"
              ></button>
            </span>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="footer">
        <tr>
          <ng-container *ngFor="let col of columns">
            <td
              *ngIf="!['project', 'user', 'doneWork'].includes(col.field)"
              [colSpan]="
                col.field === 'date'
                  ? ['projectId', 'userId'].includes(rowGroupModel)
                    ? 3
                    : 4
                  : 1
              "
              style="white-space: pre-line; position: relative"
            >
              {{
                (i == 1 ? (leaveDaysTotal | async) : (daysTotal | async))[
                  col.field
                ]
              }}
            </td>
          </ng-container>
          <td></td>
        </tr>
      </ng-template>
    </p-treeTable>
  </div>

  <p-sidebar [(visible)]="showSidebar" position="right">
    <div class="day-right-section">
      <spinner *ngIf="sectionLoading"></spinner>

      <div *ngIf="showSection === PROJECT_TYPE.PRODUCTS && !sectionLoading">
        <h1>Produkter</h1>

        <app-emptyState
          *ngIf="projectProductsDataSetCount === 0"
          [model]="'Product'"
        ></app-emptyState>

        <div *ngFor="let projectProducts of projectProductsDataSet">
          <div style="margin-bottom: 10px">
            <ng-template #productsTableCaption>
              Produkter för {{ projectProducts[0].projectTrueId }},
              {{ projectProducts[0].projectMark }}
            </ng-template>

            <app-table
              #productsTable
              *ngIf="!sectionLoading && projectProducts?.length > 0"
              [models]="projectProducts"
              [tableCaption]="productsTableCaption"
              [tableMetadata]="projectProductsTableMetadata"
            ></app-table>
          </div>
        </div>
      </div>

      <div *ngIf="showSection === PROJECT_TYPE.COMMENTS && !sectionLoading">
        <h1>Kommentarer</h1>

        <app-emptyState
          *ngIf="projectCommentsDataSetCount === 0"
          [model]="'Comment'"
        ></app-emptyState>

        <div *ngFor="let projectComments of projectCommentsDataSet">
          <div style="margin-bottom: 10px">
            <ng-template #commentsTableCaption>
              Kommentarer för {{ projectComments[0].projectTrueId }},
              {{ projectComments[0].projectMark }}
            </ng-template>

            <app-table
              #commentsTable
              *ngIf="!sectionLoading && projectComments?.length > 0"
              [models]="projectComments"
              [tableCaption]="commentsTableCaption"
              [tableMetadata]="projectCommentsTableMetadata"
            ></app-table>
          </div>
        </div>
      </div>
    </div>
  </p-sidebar>

  <p-sidebar
    showCloseIcon="true"
    [(visible)]="isDayFormVisible"
    (onHide)="hideDayFormAndReloadTimeReports()"
    position="right"
  >
    <h1 *ngIf="!dayToEdit">Skapa tidrapport</h1>
    <h1 *ngIf="dayToEdit">Redigera tidrapport</h1>
    <app-day-form
      (dayUpdatedEvent)="hideDayFormAndReloadTimeReports()"
      [productsEnabled]="true"
      [storeLastUsedProject]="false"
      [day]="{ day: dayToEdit }"
    ></app-day-form>
  </p-sidebar>
</div>
