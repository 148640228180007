<div class="page-container">
  <div class="p-grid">
    <div class="p-col-6">
      <p-card>
        <h4>Skapa kommentar</h4>
        <p>
          Här kan du skriva kommentarer som blir knutna till detta projekt.
          Kommentarerna visas inte för kunden, men de finns tillgängliga för
          alla inom bolaget. I appen syns de längst ner i projektet.
        </p>
        <textarea pInputTextarea [(ngModel)]="commentText"></textarea>

        <div class="p-d-flex p-jc-end p-col-12 p-pr-0 p-pb-0">
          <button
            pButton
            class="p-button-primary-hyperion"
            (click)="create()"
            label="Skapa"
            icon="pi pi-plus"
            type="button"
          ></button>
        </div>
      </p-card>
    </div>
    <div class="p-col-6 p-grid">
      <div class="p-col-12" *ngFor="let comment of projectComments | async">
        <p-card>
          <h4 class="p-mb-3">
            Skapad {{ comment.created }} av {{ comment.user.firstName }}
            {{ comment.user.lastName }}
          </h4>
          <textarea #commentText pInputTextarea>{{ comment.comment }}</textarea>

          <div class="p-d-flex p-jc-end p-col-12 p-pr-0 p-pb-0">
            <button
              pButton
              class="p-button-danger-inverted"
              (click)="delete(comment)"
              label="Radera"
              icon="pi pi-trash"
              type="button"
            ></button>

            <button
              pButton
              class="p-button-primary-inverted p-ml-2"
              (click)="update(comment, commentText.value)"
              label="Uppdatera"
              icon="pi pi-save"
              type="button"
            ></button>
          </div>
        </p-card>
      </div>
    </div>
  </div>
</div>
