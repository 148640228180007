import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, first, BehaviorSubject } from 'rxjs';
import {
  FetchProjectCommentsGQL,
  FetchProjectCommentsQuery,
} from './graphql/project.comments.query.generated';

import {
  CreateCommentGQL,
  UpdateCommentGQL,
  DeleteCommentGQL,
} from './graphql/project.comments.mutation.generated';
import { MessageService } from '../../../shared/message/index';
import { ProjectCountService } from 'app/project/project-count-service/project-count-service.service';

type Comment =
  FetchProjectCommentsQuery['company']['projects']['edges'][0]['node']['comments']['edges'][0]['node'];

@Component({
  selector: 'app-project-comments',
  templateUrl: './project-comments.component.html',
  styleUrls: ['./project-comments.component.scss'],
})
export class ProjectCommentsComponent implements OnInit {
  public projectComments: Observable<Array<Comment>>;
  public commentText = '';

  private projectId: number;
  private projectCommentsSub = new BehaviorSubject<Array<Comment>>([]);

  constructor(
    private route: ActivatedRoute,
    private messageService: MessageService,
    private projectCommentsService: FetchProjectCommentsGQL,
    private createCommentService: CreateCommentGQL,
    private updateCommentService: UpdateCommentGQL,
    private deleteCommentService: DeleteCommentGQL,
    private projectCountService: ProjectCountService
  ) {}

  public ngOnInit(): void {
    this.projectComments = this.projectCommentsSub.asObservable();
    this.route.parent.params.subscribe(params => {
      this.projectId = params.id;
      this.setComments();
    });
  }

  public create(): void {
    const comment = {
      comment: this.commentText,
      projectId: this.projectId,
    };

    this.createCommentService
      .mutate({
        createCommentObject: comment,
      })
      .pipe(first())
      .subscribe(result => {
        const mutationData = result?.data?.commentTypeHyperionMutation;
        if (!mutationData) {
          return;
        }
        this.projectCountService.count(this.projectId);
        this.messageService.insertDataFromMutation(mutationData);
        this.commentText = '';
        this.setComments();
      });
  }

  public update(currentComment: Comment, text: string): void {
    const comment = {
      id: Number(currentComment.id),
      comment: text,
    };

    this.updateCommentService
      .mutate({
        updateCommentObject: comment,
      })
      .pipe(first())
      .subscribe(result => {
        const mutationData = result?.data?.commentTypeHyperionMutation;
        if (!mutationData) {
          return;
        }
        this.messageService.insertDataFromMutation(mutationData);
        this.setComments();
      });
  }

  public delete(currentComment: Comment): void {
    const comment = {
      id: Number(currentComment.id),
    };

    this.deleteCommentService
      .mutate({
        deleteCommentObject: comment,
      })
      .pipe(first())
      .subscribe(result => {
        this.projectCountService.count(this.projectId);
        const mutationData = result?.data?.commentTypeHyperionMutation;
        if (!mutationData) {
          return;
        }
        this.messageService.insertDataFromMutation(mutationData);
        this.setComments();
      });
  }

  private setComments(): void {
    this.projectCommentsService
      .fetch({ id: this.projectId })
      .pipe(first())
      .subscribe(result => {
        const comments =
          result.data.company.projects.edges[0].node.comments.edges.map(
            commentEdge => commentEdge.node
          ) as Array<Comment>;
        this.projectCommentsSub.next(comments);
      });
  }
}
