import { RowType } from './enums/row-type.enum';
import * as moment from 'moment';
import { CompanyAccountsGQL } from './graphql/companyAccounts.generated';
import { first } from 'rxjs';
import { Injectable } from '@angular/core';
import { InvoiceType } from './enums/invoice-type.enum';

export interface ParsedInvoiceData {
  customers: any[];
  projects: any[];
  transactions: any[];
  transactionTypes: any[];
}

@Injectable({
  providedIn: 'root',
})
export class InvoiceParser {
  private trakHalfDayAccountingPlanAccountId: string;
  private reverseVatTrakHalfDayAccountingPlanAccountId: string;
  private trakDayAccountingPlanAccountId: string;
  private reverseVatTrakDayAccountingPlanAccountId: string;
  private companyMilageForCustomerAccountingPlanAccountId: string;
  private reverseVatCompanyMilageForCustomerAccountingPlanAccountId: string;
  private trakNightAccountingPlanAccountId: string;
  private reverseVatTrakNightAccountingPlanAccountId: string;
  /**********************
   *
   *
   *  This parser returns the following object
   *
   */

  constructor(private companyAccountsService: CompanyAccountsGQL) {
    this.companyAccountsService
      .fetch()
      .pipe(first())
      .subscribe(data => {
        this.trakHalfDayAccountingPlanAccountId =
          data.data.company.trakHalfDayAccountingPlanAccountId;
        this.reverseVatTrakHalfDayAccountingPlanAccountId =
          data.data.company.reverseVatTrakHalfDayAccountingPlanAccountId;
        this.trakNightAccountingPlanAccountId =
          data.data.company.trakNightAccountingPlanAccountId;
        this.reverseVatTrakNightAccountingPlanAccountId =
          data.data.company.reverseVatTrakNightAccountingPlanAccountId;
        this.trakDayAccountingPlanAccountId =
          data.data.company.trakDayAccountingPlanAccountId;
        this.reverseVatTrakDayAccountingPlanAccountId =
          data.data.company.reverseVatTrakDayAccountingPlanAccountId;
        this.companyMilageForCustomerAccountingPlanAccountId =
          data.data.company.companyMilageForCustomerAccountingPlanAccountId;
        this.reverseVatCompanyMilageForCustomerAccountingPlanAccountId =
          data.data.company.reverseVatCompanyMilageForCustomerAccountingPlanAccountId;
      });
  }

  private controlTransactionObject(transaction): any {
    const defaultTransactionObject = {
      miles: 0,
      enhet: 'st', // '-',
      invoiceId: 0,
      discount: 0,
      tax: 25,
      date: moment().format('YYYY-MM-DD'),
      houseWorkHoursToReport: null,
      houseWorkType: null,
    };

    return Object.assign({}, defaultTransactionObject, transaction);
  }

  public parseFromInvoice(invoice: any): ParsedInvoiceData {
    const transactionTypes = [];
    const transactions = [];
    const customers = [];
    const products = invoice.products.edges.map(x => x.node);
    const invoiceObj = invoice['rows'].map((row, i) => {
      let trueId = null;
      if (row.project !== null) {
        trueId = row.project.trueId;
      }

      transactionTypes.push({
        id: i,
        name: row.benamning,
        typeName: row.benamning,
        projectTrueId: trueId,
        mainTypeId: RowType.StandAloneRow,
      });
      transactions.push({
        ...row,
        apris: +row.apris,
        antal: +row.antal,

        discount: +row.discount,

        isExtra: false,
        mainTypeId: RowType.StandAloneRow,
        subTypeId: i,
        used: true,
        isFree: true,
        trueId: trueId,
      });
      const projects = this.cleanFromTypes(
        invoice['projects_PreInvoiceTypeHyperion']
      );
      projects.forEach(p => (p.products = [...products]));
      return {
        transactionTypes,
        transactions,
        customers,
        projects: projects,
      };
    });

    const returnVal: ParsedInvoiceData = invoiceObj[0];

    return returnVal;
  } // End parseFromInvoice()

  public parse(
    projects: any[],
    invoiceType: InvoiceType = InvoiceType.Normal
  ): ParsedInvoiceData {
    const transactionTypes: any = [];
    const transactions: any = [];
    const customers: any = [];

    projects.forEach(project => {
      const projectTrueId = project.project.trueId;
      const projectString = ' (' + projectTrueId + ')';

      project.projectUserCostTypes.forEach(projectUserCostType => {
        if (isNaN(projectUserCostType.id)) {
          // traktamenet

          let mainTypeId = RowType.SubsistenceDay;

          if (projectUserCostType.id === 'subsistenceNight') {
            mainTypeId = RowType.SubsistenceNight;
          } else if (projectUserCostType.id === 'subsistenceHalfDay') {
            mainTypeId = RowType.SubsistenceDayHalf;
          }

          let accountingPlanAccountId: string;

          if (projectUserCostType.companyUserCostType) {
            accountingPlanAccountId =
              projectUserCostType.companyUserCostType?.accountingPlanAccountId;
          } else {
            switch (mainTypeId) {
              case RowType.SubsistenceDay:
                if (invoiceType === InvoiceType.InvertedVat) {
                  accountingPlanAccountId =
                    this.reverseVatTrakDayAccountingPlanAccountId;
                } else {
                  accountingPlanAccountId = this.trakDayAccountingPlanAccountId;
                }
                break;
              case RowType.SubsistenceDayHalf:
                if (invoiceType === InvoiceType.InvertedVat) {
                  accountingPlanAccountId =
                    this.reverseVatTrakHalfDayAccountingPlanAccountId;
                } else {
                  accountingPlanAccountId =
                    this.trakHalfDayAccountingPlanAccountId;
                }
                break;
              case RowType.SubsistenceNight:
                if (invoiceType === InvoiceType.InvertedVat) {
                  accountingPlanAccountId =
                    this.reverseVatTrakNightAccountingPlanAccountId;
                } else {
                  accountingPlanAccountId =
                    this.trakNightAccountingPlanAccountId;
                }
                break;
            }
          }

          transactionTypes.push({
            id: projectUserCostType.id,
            name: projectUserCostType.name + projectString,
            typeName: projectUserCostType.name,
            projectTrueId: projectTrueId,
            mainTypeId: mainTypeId,
            accountingPlanAccountId: accountingPlanAccountId,
          });
        } else {
          transactionTypes.push({
            id: +projectUserCostType.id,
            name: projectUserCostType.name + projectString,
            typeName: projectUserCostType.name,
            projectTrueId: projectTrueId,
            mainTypeId: RowType.Days,
            accountingPlanAccountId:
              invoiceType === InvoiceType.InvertedVat
                ? projectUserCostType.companyUserCostType
                    ?.reverseVatAccountingPlanAccountId
                : projectUserCostType.companyUserCostType
                    ?.accountingPlanAccountId,
          });
        }
      });

      project.projectCostTypes.forEach(projectCostType => {
        const companyCostType = project.companyCostTypes.find(
          costType => +costType.id === +projectCostType.companyCostTypeId
        );
        let companyCostTypeUnit = null;

        if (companyCostType) {
          companyCostTypeUnit = companyCostType.unit;
        }

        const accountingPlanAccountId =
          invoiceType === InvoiceType.InvertedVat
            ? companyCostType?.reverseVatAccountingPlanAccountId
            : companyCostType?.accountingPlanAccountId;

        transactionTypes.push({
          id: +projectCostType.id,
          name: projectCostType.name + projectString,
          typeName: projectCostType.name,
          projectTrueId: projectTrueId,
          mainTypeId: RowType.Products,
          unit: companyCostTypeUnit,
          accountingPlanAccountId: accountingPlanAccountId,
        });
      });

      this.parseDaysList(
        project,
        transactions,
        project.projectUserCostTypes,
        project.milePrice
      );

      const projectCostTypesPushed = false;
      project.products.forEach(itm => {
        const costType = project.projectCostTypes.find(
          pct => +pct.id === +itm.projectCostTypeId
        );
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        costType && transactions.push(this.createProduct(itm, false, project));
      });
      project.productsExtra.forEach(itm => {
        const costType = project.projectCostTypes.find(
          pct => +pct.id === +itm.projectCostTypeId
        );
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        costType && transactions.push(this.createProduct(itm, true, project));
      });

      if (project?.offerFull?.todos) {
        transactionTypes.push({
          id: 1,
          name: 'Arbetsmoment',
          typeName: 'Arbetsmoment offert ' + project.offerFull.trueId,
          projectTrueId: projectTrueId,
          mainTypeId: RowType.Offer,
        });
        project.offerFull.todos.forEach(itm => {
          transactions.push(
            this.controlTransactionObject({
              id: itm.id,
              benamning: itm.descriptionOffer,
              display: {
                date: itm.created,
                name: itm.descriptionOffer,
                user: 'User',
              },
              date: itm.created,
              apris: +itm.unitPrice,
              antal: +itm.quantity,
              // eslint-disable-next-line eqeqeq
              arbetskostnad: itm.rot == 1,
              mainTypeId: RowType.Offer,
              subTypeId: 1,
              project_id: +project.project.id,
              trueId: project.project.trueId,
            })
          );
        });
      }

      if (project?.offerFull?.calculationRows) {
        transactionTypes.push({
          id: 2,
          name: 'Produkter',
          typeName: 'Produkter offert ' + project.offerFull.trueId,
          projectTrueId: projectTrueId,
          mainTypeId: RowType.Offer,
        });
        project.offerFull.calculationRows.forEach(itm => {
          transactions.push(
            this.controlTransactionObject({
              id: itm.id,
              benamning: itm.name,
              display: { date: itm.created, name: itm.name, user: 'User' },
              date: itm.created,
              apris: +itm.costPlusProcent,
              antal: +itm.quantity,
              invoiceId: 0,
              mainTypeId: RowType.Offer,
              subTypeId: 2,
              product_id: +itm.productId,
              project_id: +project.project.id,
              trueId: project.project.trueId,
            })
          );
        });
      }

      // ATA
      project.atas.map(ata => {
        if (ata.todos) {
          transactionTypes.push({
            id: +ata.id + '001',
            name: 'Arbetsmoment ÄTA ' + ata.trueId,
            typeName: 'Arbetsmoment ÄTA ' + ata.trueId,
            projectTrueId: projectTrueId,
            mainTypeId: RowType.ATA,
          });
          ata.todos.forEach(itm => {
            transactions.push(
              this.controlTransactionObject({
                id: itm.id,
                benamning: itm.descriptionOffer,
                display: {
                  date: itm.created,
                  name: itm.descriptionOffer,
                  user: 'User',
                },
                date: itm.created,
                apris: +itm.unitPrice,
                antal: +itm.quantity,
                // eslint-disable-next-line eqeqeq
                arbetskostnad: itm.rot == 1,
                mainTypeId: RowType.ATA,
                subTypeId: ata.id + '001',
                project_id: +project.project.id,
                trueId: project.project.trueId,
              })
            );
          });
        }

        if (ata.calculationRows) {
          transactionTypes.push({
            id: +ata.id + '002',
            name: 'Produkter ÄTA ' + ata.trueId,
            typeName: 'Produkter ÄTA ' + ata.trueId,
            projectTrueId: projectTrueId,
            mainTypeId: RowType.ATA,
          });
          ata.calculationRows.forEach(itm => {
            transactions.push(
              this.controlTransactionObject({
                id: itm.id,
                benamning: itm.name,
                display: { date: itm.created, name: itm.name, user: 'User' },
                date: itm.created,
                apris: +itm.costPlusProcent,
                antal: +itm.quantity,
                invoiceId: 0,
                mainTypeId: RowType.ATA,
                subTypeId: ata.id + '002',
                project_id: +project.project.id,
                trueId: project.project.trueId,
              })
            );
          });
        }
      });

      // SLUT ATA

      if (project.project.offertSum > 0) {
        transactionTypes.push({
          id: project.project.id + '001',
          name: 'Offererat material',
          typeName: 'Offererat material',
          projectTrueId: projectTrueId,
          mainTypeId: RowType.OfferFromProject,
        });
        transactions.push(
          this.controlTransactionObject({
            id: 4683434454,
            benamning: 'benaminng',
            display: { date: '', name: 'Offererat material', user: 'User' },
            apris: project.project.offertSum,
            antal: 1,
            mainTypeId: RowType.OfferFromProject,
            subTypeId: project.project.id + '001',
            project_id: +project.project.id,
            trueId: project.project.trueId,
          })
        );
      }
      if (project.project.offertSumWork > 0) {
        transactionTypes.push({
          id: project.project.id + '002',
          name: 'Offererat arbete',
          typeName: 'Offererat arbete',
          projectTrueId: projectTrueId,
          mainTypeId: RowType.OfferFromProject,
        });
        transactions.push(
          this.controlTransactionObject({
            id: 4684523434446,
            benamning: 'benaminng',
            display: { date: '', name: 'Offererat arbete', user: 'User' },
            apris: project.project.offertSumWork,
            antal: 1,
            mainTypeId: RowType.OfferFromProject,
            subTypeId: project.project.id + '002',
            project_id: +project.project.id,
            trueId: project.project.trueId,
          })
        );
      }

      project.installments.forEach(itm => {
        transactionTypes.push({
          id: +itm.id,
          name: itm.name + projectString,
          typeName: itm.name,
          projectTrueId: projectTrueId,
          mainTypeId: RowType.Installaments,
        });
        itm.rows.forEach(row => {
          if (!(+row.invoiceId > 0)) {
            transactions.push({
              id: row.id,
              benamning: row.benamning,
              arbetskostnad: +row.arbetskostnad === 1,
              display: {
                date: itm.created,
                name: row.benamning + ' (' + itm.dateToInvoice + ')',
                user: 'User',
              },
              date: itm.created,
              apris: +row.apris,
              miles: 0,
              antal: +row.antal,
              invoiceId: +row.invoiceId,
              discount: 0,
              tax: !isNaN(+row.tax) ? +row.tax : 0,
              enhet: 'st',
              mainTypeId: RowType.Installaments,
              subTypeId: +itm.id,
              project_id: +itm.projectId,
              trueId: project.project.trueId,
            });
          }
        });
      });

      customers.push({
        trueId: project.contact.trueId,
        address: project.contact.address,
        address2: project.contact.address2,
        city: project.contact.city,
        cityCode: project.contact.cityCode,
        name: project.contact.name,
        mail: project.contact.mail,
        mobilePhone: project.contact.mobilePhone,
        orderBuisnessName: project.contact.orderBuisnessName,
        orgNr: project.contact.orgNr,
        phone: project.contact.phone,
      });
    });

    transactions.map(itm => {
      (itm.apris = Math.round(itm.apris * 100) / 100),
        (itm.miles = Math.round(itm.miles * 100) / 100),
        (itm.antal = Math.round(itm.antal * 100) / 100);
    });
    return {
      transactionTypes: transactionTypes,
      transactions: transactions,
      customers: customers,
      projects: projects,
    };
  } // End parse()

  private createProduct(itm: any, isExtra: boolean, project): any {
    let mainTypeId = RowType.Products;
    if (isExtra) {
      mainTypeId = RowType.ProductsExtra;
    }

    return {
      id: itm.id,
      benamning: itm.benamning,
      display: { date: itm.date, name: itm.benamning, user: 'User' },
      date: itm.date,
      apris: Number(itm.avtalspris),
      miles: 0,
      antal: Number(itm.antal),
      invoiceId: 0,

      discount: 0,
      isExtra: isExtra,
      tax: 25,
      enhet: itm.enhet, // itm.enhet,
      mainTypeId: mainTypeId,
      subTypeId: Number(itm.projectCostTypeId),
      project_id: Number(itm.projectId),
      product_id: Number(itm.productId),
      trueId: project.project.trueId,
      product: itm.productId
        ? {
            id: itm.productId,
            artnr: itm.artnr,
            benamning: itm.benamning,
          }
        : null,
    };
  }

  private parseDaysList(
    project,
    rowDetail: any[],
    projectUserCostTypes: any[],
    milePrice
  ): void {
    project.daysList.forEach(itm => {
      let projecUserCostType = projectUserCostTypes.find(
        puct => +puct.id === +itm.costTypeId
      );
      let mainTypeId = RowType.Days;
      if (+itm.extra === 1) {
        mainTypeId = RowType.DaysExtra;
      }

      let subTypeId = +itm.costTypeId;

      if (isNaN(itm.costTypeId)) {
        /* eslint-disable eqeqeq */
        projecUserCostType = projectUserCostTypes.find(
          puct => puct.id == itm.costTypeId
        );
        /* eslint-enable eqeqeq */

        subTypeId = itm.costTypeId;

        if (itm.costTypeId === 'subsistenceNight') {
          mainTypeId = RowType.SubsistenceNight;
        } else if (itm.costTypeId === 'subsistenceHalfDay') {
          mainTypeId = RowType.SubsistenceDayHalf;
        } else if (itm.costTypeId === 'subsistenceDay') {
          mainTypeId = RowType.SubsistenceDay;
        }
      }
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      projecUserCostType &&
        rowDetail.push(
          this.controlTransactionObject({
            id: itm.id,
            benamning: itm.doneWork,
            display: { date: itm.date, name: itm.doneWork, user: 'User' },
            date: itm.date,
            apris: +projecUserCostType.cost,
            miles: +itm.mile,
            invoiceId: 0,
            milesApris: +milePrice,
            antal: +itm.hours,
            isExtra: +itm.extra === 0 ? false : true,
            enhet: [
              RowType.SubsistenceDay,
              RowType.SubsistenceNight,
              RowType.SubsistenceDayHalf,
            ].includes(mainTypeId)
              ? 'st'
              : 'tim', // 'Timmar',   // For Now
            mainTypeId: mainTypeId,
            subTypeId: subTypeId,
            project_id: +itm.projectId,
            trueId: project.project.trueId,
          })
        );
    });
  }

  private cleanFromTypes(data): any[] {
    const newDat = [];
    data.edges.forEach(element => {
      const id = element.node.id;
      const mark = element.node.mark;
      const trueId = element.node.trueId;
      newDat.push({ id, mark, trueId });
    });
    return newDat;
  }
}
