import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Table } from 'primeng/table';
import PROJECT_STATUS, {
  PROJECT_STATUS_LABELS,
} from 'app/shared/global/project-status.enum';
import * as FileSaver from 'file-saver';
import { FilterService } from 'primeng/api';
import { Contact } from 'generated/types';
import { Column, KpiColumns } from '../project-list.component';
import { first, map } from 'rxjs';
import { ProjectListService } from '../project-list.service';
import { FormGroup } from '@angular/forms';
import { write, utils, WorkBook } from 'xlsx-js-style';
import { MultiSelectUserFragment } from 'app/shared/users-multiselect/graphql/multiselectUsers.generated';

@Component({
  selector: 'app-project-extended-search',
  templateUrl: './project-extended-search.component.html',
  styleUrls: ['./project-extended-search.component.scss'],
})
export class ProjectExtendedSearchComponent implements OnInit {
  @Input() public table: Table;
  @Input() public infoColumns: Column[];
  @Input() public kpiColumns: KpiColumns[];
  @Input() public filters: FormGroup;
  @Output() public searchCriteriaChanged: EventEmitter<void> =
    new EventEmitter();

  public statusLabels = [...PROJECT_STATUS_LABELS].filter(
    l => l.value !== PROJECT_STATUS.DELETED && l.value !== PROJECT_STATUS.LEAVE
  );
  public loadingExcel = false;
  public loadingExport = false;

  public get startDate(): Date {
    return this.projectListService.dateSpan.startDate;
  }
  public set startDate(value: Date) {
    this.projectListService.dateSpan.startDate = value;
  }

  public get endDate(): Date {
    return this.projectListService.dateSpan.endDate;
  }
  public set endDate(value: Date) {
    this.projectListService.dateSpan.endDate = value;
  }

  constructor(
    private filterService: FilterService,
    protected projectListService: ProjectListService
  ) {}

  public filterDate(): void {
    this.searchCriteriaChanged.emit();
  }

  public selectedKpiColumns: any[] = [];

  public OnKpiColumnChange(event: any[]): void {
    this.kpiColumns.forEach(category => {
      category.items.forEach(
        kpi => (kpi.active = this.selectedKpiColumns.includes(kpi.field))
      );
    });
  }

  public formatDate(date: Date): string {
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
  }

  public exportTable(): void {
    this.exportExcel();
  }

  private prepDataForExport(data) {
    return data.map(val => {
      const exportVal = {};
      this.infoColumns
        .filter(c => c.active)
        .forEach(
          c => (exportVal[c.header] = this.getValueAtPath(c.field, val))
        );
      this.kpiColumns
        .flatMap(x => x.items)
        .filter(c => c.active)
        .forEach(
          c => (exportVal[c.header] = Number(this.getValueAtPath(c.field, val)))
        );
      return exportVal;
    });
  }

  private exportExcel(): void {
    this.loadingExcel = true;
    this.projectListService
      .fetchProjectsWithKPIs(
        this.infoColumns,
        this.kpiColumns,
        this.filters.value
      )
      .pipe(
        first(),
        map(res => [
          res.data.company.projectsWithKpis.edges.map(e => e.node),
          res.data.company.projectsWithKpisSummary,
        ])
      )
      .subscribe(([data, summary]) => {
        const worksheet = utils.json_to_sheet([
          ...this.prepDataForExport(data),
          ...[{ ID: 'Summa:' }],
          ...this.prepDataForExport([summary]),
        ]);

        const infoCols = this.infoColumns
          .filter(c => c.active)
          .map(c => parseInt(c.width.replace('rem', '')) * 2 || 10);
        const kpiCols = this.kpiColumns
          .flatMap(x => x.items)
          .filter(c => c.active)
          .map(c => parseInt(c.width.replace('rem', '')) || 13);

        worksheet['!cols'] = [...infoCols, ...kpiCols].map(width => {
          return { wch: width };
        });

        const fmt = '#,##0.00';
        const kpiStartingColumn = infoCols.length;
        const range = utils.decode_range(worksheet['!ref']);
        for (let j = 0; j <= range.e.c; ++j) {
          const ref = utils.encode_cell({ r: 0, c: j });
          if (!worksheet[ref]) continue;
          worksheet[ref].s = {
            font: {
              bold: true,
            },
          };
        }
        for (let i = 1; i <= range.e.r; ++i) {
          for (let j = kpiStartingColumn; j <= range.e.c; ++j) {
            const ref = utils.encode_cell({ r: i, c: j });
            if (!worksheet[ref]) continue;
            if (worksheet[ref].t != 'n') continue;
            worksheet[ref].z = fmt;
          }
        }

        const workbook: WorkBook = {
          Sheets: { data: worksheet },
          SheetNames: ['data'],
        };
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const excelBuffer: any = write(workbook, {
          bookType: 'xlsx',
          type: 'array',
          cellStyles: true,
        });
        this.saveAsExcelFile(excelBuffer, 'Projekt');
        this.loadingExcel = false;
      });
  }

  public exportPerCostTypeExcel(): void {
    this.loadingExport = true;
    this.projectListService
      .fetchIdsForExport(this.filters.value)
      .subscribe(ids => {
        const projectIds = ids.map(id => 'p' + id).join();
        location.href =
          '/company/exportPerCostType?projects=' +
          projectIds +
          '&fromDate=' +
          this.formatDate(this.startDate) +
          '&toDate=' +
          this.formatDate(this.endDate);
        this.loadingExport = false;
      });
  }

  private saveAsExcelFile(buffer: any, filename: string): void {
    const EXCEL_TYPE =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    FileSaver.saveAs(
      data,
      filename + '_export_' + new Date().getTime() + EXCEL_EXTENSION
    );
  }

  public getValueAtPath(path: string, obj: any): any {
    const value = path.split('.').reduce((acc, c) => acc && acc[c], obj);
    if (value) {
      return String(value).replaceAll('\n', ', ');
    }
    return null;
  }

  public selectCreatedByIdDropdown(users: MultiSelectUserFragment[]): void {
    const userIds = users.map(user => Number(user.id));
    this.projectListService.createdByIds = userIds;
  }

  public ngOnInit(): void {
    this.filterService.register(
      'contactContains',
      (value: Contact, filter: string): boolean => {
        const contains = val =>
          this.filterService.filters.contains(val, filter);
        return Object.keys(value).some(key => contains(value[key]));
      }
    );
  }
}
