<div class="page-container p-grid">
  <span class="p-col-12 p-d-flex p-jc-between p-ai-center">
    <span>
      <p-splitButton
        class="p-mr-3 p-element p-button p-component"
        icon="pi pi-send"
        label="Skicka"
        (onClick)="showMailModule()"
        [model]="sendMailItems"
        appendTo="body"
      ></p-splitButton>
      <button
        pButton
        icon="pi pi-file-pdf"
        label="Visa PDF"
        (click)="showPdf()"
      ></button>
    </span>
    <button
      pButton
      (click)="createTodo()"
      class="p-button-primary-hyperion"
      icon="pi pi-plus"
      label="Skapa arbetsmoment"
    ></button>
  </span>
  <p-table
    [value]="todoTopics | async"
    dataKey="id"
    class="p-col-12"
    [rowHover]="true"
  >
    <ng-template pTemplate="header">
      <tr>
        <th
          *ngFor="let col of outerColumns"
          [pSortableColumn]="col.field"
          [style.width]="col.width"
          [style.whiteSpace]="'break-spaces'"
        >
          <span class="p-d-flex p-jc-between p-ai-center">
            {{ col.header }}
            <p-sortIcon [field]="col.field"></p-sortIcon>
          </span>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-topic let-expanded="expanded">
      <tr [pRowToggler]="topic">
        <td class="p-text-center">
          <i
            [class]="topic.done ? 'pi pi-check' : 'pi pi-times'"
            [style.color]="
              topic.done
                ? 'var(--btn-success-color)'
                : 'var(--btn-danger-color)'
            "
          >
          </i>
        </td>
        <td class="p-text-bold">
          <span class="p-d-flex p-jc-between">
            {{ topic.description }}
            <i
              class="pi pi-chevron-down"
              [style.transform]="expanded ? 'rotate(0deg)' : 'rotate(-90deg)'"
            ></i>
          </span>
        </td>
        <td class="p-text-center">{{ topic.startDate }}</td>
        <td class="p-text-center">{{ topic.endDate }}</td>
        <td>{{ topic.estimatedTime }}h</td>
        <td>{{ topic.timeUsed }}h</td>
      </tr>
    </ng-template>
    <ng-template pTemplate="rowexpansion" let-topic>
      <tr>
        <td colspan="6">
          <app-project-todo-subtable
            (editEmit)="editTodo($event)"
            (updateEmit)="onUpdate()"
            [topic]="topic"
          ></app-project-todo-subtable>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>

<p-sidebar position="right" [(visible)]="showCreateTodo" class="p-col-12">
  <h1>
    {{ (isEdit | async) ? 'Redigera arbetsmoment' : 'Skapa arbetsmoment' }}
  </h1>
  <app-project-todo-form
    [todoIdAsync]="editTodoId"
    [isEditAsync]="isEdit"
    [projectId]="projectId"
    (updateEmit)="onUpdate($event)"
  ></app-project-todo-form>
</p-sidebar>
