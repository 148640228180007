<div class="p-grid">
  <div class="p-col-12 p-lg-5">
    <hyperion-card *ngIf="subcontractorData.approvedByCompanyId !== 'self'">
      <div class="p-grid">
        <div class="p-col-12">
          <h2>
            Hantera företag
            <span class="strong-text">{{ subcontractorData['name'] }}</span>
          </h2>
        </div>
        <div class="p-col-12 p-g-nopad">
          <form
            [formGroup]="componentMainForm"
            (ngSubmit)="actionUpdate()"
            class="full-width no-margin inline-grid-label-form"
          >
            <appForm2-wide-input formControlName="name"></appForm2-wide-input>

            <appForm2-wide-input formControlName="email"></appForm2-wide-input>

            <appForm2-wide-input formControlName="orgNr"></appForm2-wide-input>
          </form>
        </div>
        <div class="p-col-12">
          <div class="pull-right">
            <app-button
              [disabled]="loading | async"
              (onClick)="actionDelete()"
              [type]="'delete'"
            ></app-button>
            <app-button
              class="ml5"
              [disabled]="loading | async"
              (onClick)="actionUpdate()"
              [type]="'update'"
            ></app-button>
          </div>
        </div>
      </div>
    </hyperion-card>
  </div>
  <div class="p-col-12 p-lg-7">
    <hyperion-card>
      <div class="p-grid">
        <div class="p-col-12">
          <h2>
            Hantera personer i
            <span class="strong-text">{{ subcontractorData.name }}</span>
            <app-button
              [class]="'block'"
              (onClick)="openAddAttendanceFreeUserDialog()"
              [type]="'add'"
            ></app-button>
          </h2>
        </div>
        <div class="p-col-12 p-g-nopad">
          <free-users
            [usersData]="freeUsers | async"
            (mutationError)="getFreeUsers()"
          ></free-users>
        </div>
      </div>
    </hyperion-card>
  </div>
</div>
