<p-sidebar position="right" [(visible)]="showCreate">
  <h1>{{ planId ? 'Redigera' : 'Skapa' }} delbetalning</h1>
  <form [formGroup]="createForm">
    <div class="p-grid">
      <span class="p-col-12">
        <label>Ansvarig</label>
        <p-dropdown
          [options]="users | async"
          optionValue="id"
          placeholder="Välj användare..."
          [formControl]="createForm.controls.userId"
        >
          <ng-template pTemplate="item" let-user>
            {{ user.firstName }} {{ user.lastName }}
          </ng-template>
          <ng-template pTemplate="selectedItem" let-user>
            {{ user.firstName }} {{ user.lastName }}
          </ng-template>
        </p-dropdown>
      </span>
      <span class="p-col-12">
        <label>Ska faktureras*</label>
        <p-calendar
          dataType="string"
          [formControl]="createForm.controls.date"
          showIcon="true"
          [showWeek]="true"
        ></p-calendar>
      </span>
      <span class="p-col-12">
        <label>Benämning</label>
        <input pInputText [formControl]="createForm.controls.name" />
      </span>
      <span class="p-col-12 p-d-flex p-jc-end">
        <button
          *ngIf="!planId"
          pButton
          class="p-button-primary-hyperion"
          label="Skapa delbetalning"
          icon="pi pi-plus"
          (click)="addPlan()"
        ></button>
        <button
          *ngIf="planId"
          pButton
          class="p-button-primary-hyperion"
          label="Spara delbetalning"
          icon="pi pi-save"
          (click)="editPlan()"
        ></button>
      </span>
    </div>
  </form>
</p-sidebar>
<div class="page-container">
  <div class="p-grid">
    <div class="p-col-12 p-d-flex p-jc-between p-ai-center">
      <span>
        <h1>Betalplan</h1>
        <h3>Skapade delbetalningar läses enkelt in på aktuell faktura</h3>
      </span>
      <span>
        <button
          (click)="actionDownloadPDF()"
          pButton
          class="p-button-primary-inverted p-mr-2"
          label="Hämta PDF"
          icon="pi pi-file-pdf"
        ></button>
        <button
          (click)="actionCreatePlan()"
          pButton
          class="p-button-primary-hyperion"
          label="Skapa delbetalning"
          icon="pi pi-plus"
        ></button>
      </span>
    </div>
    <p-card *ngFor="let plan of plans | async; index as i" class="p-col-12">
      <ng-template pTemplate="header">
        <span class="p-d-flex p-jc-between p-ai-center">
          <span>
            <h3>{{ plan.name }}</h3>
            <h5>Ska faktureras datum: {{ plan.dateToInvoice }}</h5>
            <h5>
              Skapad av: {{ plan.user?.firstName }} {{ plan.user?.lastName }}
            </h5>
          </span>
          <span>
            <button
              pButton
              class="p-mr-2"
              icon="pi pi-pencil"
              (click)="actionEditPlan(plan)"
            ></button>
            <button
              pButton
              class="p-button-icon-danger"
              icon="pi pi-trash"
              label="Radera delbetalning"
              (click)="deletePlan(plan.id)"
            ></button>
          </span>
        </span>
      </ng-template>
      <div class="p-grid">
        <p-table
          class="inline-table no-alternating p-col-12"
          [value]="plan.rows"
          editMode="row"
          dataKey="id"
        >
          <ng-template pTemplate="header">
            <tr>
              <th
                *ngFor="let col of columns"
                [style.width]="col.width"
                [pSortableColumn]="col.field"
              >
                <span
                  [class]="
                    col.sortable
                      ? 'p-d-flex p-jc-between'
                      : 'p-d-flex p-jc-center'
                  "
                >
                  <i
                    [class]="col.icon"
                    [pTooltip]="col.tooltip"
                    *ngIf="col.icon"
                  ></i>
                  {{ col.header }}
                  <p-sortIcon
                    [field]="col.field"
                    *ngIf="col.sortable"
                  ></p-sortIcon>
                </span>
              </th>
              <th [style.width]="'8rem'"></th>
            </tr>
          </ng-template>
          <ng-template
            pTemplate="body"
            let-value
            let-rowIndex="rowIndex"
            let-editing="editing"
          >
            <tr [formGroup]="rowEditForms[i][rowIndex]" [pEditableRow]="value">
              <td class="p-text-center">
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <p-checkbox
                      [disabled]="!value.invoiceId"
                      [binary]="true"
                      [formControl]="
                        rowEditForms[i][rowIndex].controls.invoiced
                      "
                    ></p-checkbox>
                  </ng-template>
                  <ng-template pTemplate="output">
                    <p-checkbox
                      [disabled]="true"
                      [binary]="true"
                      [formControl]="
                        rowEditForms[i][rowIndex].controls.invoiced
                      "
                      [pTooltip]="
                        value.invoiceId
                          ? 'Raden har blivit fakturerad'
                          : 'Raden har ej blivit fakturerad'
                      "
                    ></p-checkbox>
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="output">
                    {{ (rowTypeMap | async)[value.typeId] }}
                  </ng-template>
                  <ng-template pTemplate="input">
                    <p-dropdown
                      [options]="rowTypes | async"
                      optionLabel="description"
                      optionValue="key"
                      formControlName="typeId"
                    ></p-dropdown>
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="output">
                    {{ value.benamning }}
                  </ng-template>
                  <ng-template pTemplate="input">
                    <input
                      pInputText
                      formControlName="benamning"
                      placeholder="Benämning*"
                    />
                  </ng-template>
                </p-cellEditor>
              </td>
              <td class="p-text-center">
                <p-cellEditor>
                  <ng-template pTemplate="output">
                    <i
                      [class]="
                        value.arbetskostnad ? 'pi pi-check' : 'pi pi-times'
                      "
                    ></i>
                  </ng-template>
                  <ng-template pTemplate="input">
                    <p-checkbox
                      [binary]="true"
                      [formControl]="
                        rowEditForms[i][rowIndex].controls.arbetskostnad
                      "
                    ></p-checkbox>
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="output">
                    {{ convertToNumber(value.antal) ?? value.antal }} st
                  </ng-template>
                  <ng-template pTemplate="input">
                    <input pInputText formControlName="antal" type="number" />
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="output">
                    {{
                      (convertToNumber(value.apris) | currency: 'SEK') ??
                        value.apris
                    }}
                  </ng-template>
                  <ng-template pTemplate="input">
                    <input pInputText formControlName="apris" type="number" />
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="output">
                    {{ value.tax }} %
                  </ng-template>
                  <ng-template pTemplate="input">
                    <p-dropdown
                      [options]="taxTypes | async"
                      optionLabel="description"
                      optionValue="key"
                      formControlName="tax"
                    ></p-dropdown>
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="output">
                    {{
                      (convertToNumber(value.pris) | currency: 'SEK') ??
                        value.pris
                    }}
                  </ng-template>
                  <ng-template pTemplate="input">
                    {{
                      convertToNumber(
                        rowEditForms[i][rowIndex].controls.apris.value
                      ) *
                        convertToNumber(
                          rowEditForms[i][rowIndex].controls.antal.value
                        ) | currency: 'SEK'
                    }}
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                <span class="p-d-flex p-jc-between">
                  <button
                    pSaveEditableRow
                    *ngIf="editing"
                    pButton
                    pInitEditableRow
                    class="p-button-text legacy-rounded-button p-button-icon-success"
                    icon="pi pi-check"
                    (click)="editRow(rowEditForms[i][rowIndex], value.id)"
                    type="button"
                  ></button>
                  <button
                    pCancelEditableRow
                    *ngIf="editing"
                    pButton
                    class="p-button-text legacy-rounded-button p-button-icon-danger"
                    icon="pi pi-times"
                    type="button"
                    (click)="
                      rowEditForms[i][rowIndex] = editRowFormGroup(value)
                    "
                  ></button>
                  <button
                    *ngIf="!editing"
                    pButton
                    pInitEditableRow
                    class="p-button-text legacy-rounded-button"
                    icon="pi pi-pencil"
                    type="button"
                  ></button>
                  <button
                    *ngIf="!editing"
                    pButton
                    (click)="deleteRow(value.id)"
                    class="p-button-text legacy-rounded-button p-button-icon-danger"
                    icon="pi pi-trash"
                    type="button"
                  ></button>
                </span>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="footer">
            <tr [formGroup]="rowCreateForms[i]">
              <td></td>
              <td>
                <p-dropdown
                  [options]="rowTypes | async"
                  optionLabel="description"
                  optionValue="key"
                  formControlName="typeId"
                  appendTo="body"
                ></p-dropdown>
              </td>
              <td>
                <input
                  pInputText
                  formControlName="benamning"
                  placeholder="Benämning*"
                />
              </td>
              <td class="p-text-center">
                <p-checkbox
                  [binary]="true"
                  [formControl]="rowCreateForms[i].controls.arbetskostnad"
                ></p-checkbox>
              </td>
              <td>
                <input
                  pInputText
                  formControlName="antal"
                  placeholder="0"
                  #antal
                  type="number"
                />
              </td>
              <td>
                <input
                  pInputText
                  formControlName="apris"
                  placeholder="0"
                  #apris
                  type="number"
                />
              </td>
              <td>
                <p-dropdown
                  [options]="taxTypes | async"
                  optionLabel="description"
                  optionValue="key"
                  formControlName="tax"
                  appendTo="body"
                ></p-dropdown>
              </td>
              <td>
                {{ apris.value * antal.value | currency: 'SEK' }}
              </td>
              <td>
                <button
                  pButton
                  class="p-button-primary-hyperion"
                  label="Skapa"
                  icon="pi pi-plus"
                  (click)="createRow(rowCreateForms[i], plan.id)"
                  type="button"
                ></button>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </p-card>
    <p-card class="p-col-12" *ngIf="(plans | async).length > 0">
      <div class="p-grid">
        <span class="p-col p-text-right"> Totala summan att fakturera: </span>
        <span class="p-col-2 p-text-bold">
          {{ totalAmount | currency: 'SEK' }}</span
        >
      </div>
    </p-card>
  </div>
</div>
