import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Table } from 'primeng/table';

import { Router } from '@angular/router';
import { ConfirmationService, FilterService, MenuItem } from 'primeng/api';

import { HelperService, ApolloMutationService } from 'app/shared';
import { HyperionLabelsService } from 'app/shared/labels';
import { SortService } from 'app/store/sort.service';
import { GlobalService } from 'app/shared/global';
import { AppDialogService, OfferDataComponent } from 'app/shared/dialogs';
import { SingleOfferComponent } from '../single/single-offer.component';
import { CustomSort } from 'app/store/custom-sort';
import { debounceTime, first, map } from 'rxjs/operators';
import moment from 'moment';
import { MailDialogService } from 'app/shared/dialogs/mail/mail-dialog.service';
import { ActiveItemLabelService } from 'app/header/active-item-label.service';
import { ConvertNumberService } from 'app/shared/convert-number.service';
import { MultiSelectUserFragment } from 'app/shared/users-multiselect/graphql/multiselectUsers.generated';
import {
  MoveOfferToStatusGQL,
  OfferInformationFragment,
  FetchAtasGQL,
  FetchOffersGQL,
} from './graphql/offer-list.generated';
import { SplitIoService } from 'app/shared/split-io.service';
import FileSaver from 'file-saver';
import xlsx, { utils } from 'xlsx-js-style';

export enum OfferState {
  UNANSWERED = 1,
  ACCEPTED = 2,
  REJECTED = 3,
}

const OFFER_TYPE_ATA = 'ata';
const ACTIVE_ROUTE = {
  offerDrafts: 0,
  createdOffers: 1,
  archievedOffers: 2,
};

type Offer = OfferInformationFragment & {
  trueId: number;
};

@Component({
  selector: 'app-offer-main',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [AppDialogService],
})
export class OfferIndexComponent implements OnInit, OnDestroy {
  @Input() type;
  @Input() projectInfo;
  @Output() updateProjectAtaNavCount = new EventEmitter();
  @ViewChild('offerTable', { static: true }) private offerTable: Table;

  labelModels = [{ model: 'Offer' }];
  public dataSetAsync = new BehaviorSubject<Offer[]>([]);
  loadingAsync = new BehaviorSubject(true);
  statusText = new BehaviorSubject('Skapade');
  labels;
  public isCreateMode = false; // for single offer to hide create mode
  public isAllSelected = new BehaviorSubject(false); // fo showing all row are selected
  showConfirmation = false;
  moveOfferToOngoing = false;
  cols: { field: string; header: any }[];
  offerTableColumns: any[];
  stateDropdown = [
    { value: null, label: 'Ingen status', dot: false },
    {
      value: OfferState.UNANSWERED,
      label: 'Obesvarad',
      dot: true,
      dotClass: 'warning',
    },
    {
      value: OfferState.ACCEPTED,
      label: 'Accepterad',
      dot: true,
      dotClass: 'success',
    },
    {
      value: OfferState.REJECTED,
      label: 'Avböjd',
      dot: true,
      dotClass: 'danger',
    },
  ];
  sendedDropdown = [
    { value: null, label: 'Välj' },
    { value: 0, label: 'Ej skickade' },
    { value: 1, label: 'Skickade' },
  ];
  searchstateDropdown = [];
  atasSub: Subscription;
  querySub: Subscription;
  routeSub: Subscription;
  globalSearchString: any;
  setStatusText = {
    0: 'Utkast',
    1: 'Skapade',
    2: 'Arkiverade',
  };
  sort: CustomSort = {
    attribute: 'trueId',
    ascDesc: 1,
    object: 'offer_table',
  };
  status = 1;
  selectedOffers = [];
  handleSelectedOffersDropdown = [];
  selectedOrders = [];
  scrollShouldHappen = false;
  public showAdvancedSearch = false;
  public showCreateAtaDialog: boolean;
  public offerVariable: { id: number; type: string };
  apolloVariables: { query: any; variables: any };
  firstDataPullDone: boolean;
  public first = 0;
  private isAta = false;

  public optionsMenusMap: { [key: number]: MenuItem[] } = {};
  public startDate: string;
  public endDate: string;
  public showFilterSentZigned = new BehaviorSubject<boolean>(false);

  constructor(
    public router: Router,
    private sortService: SortService,
    public helperService: HelperService,
    private activeRoute: ActivatedRoute,
    private globalService: GlobalService,
    private dialogService: AppDialogService,
    private labelsService: HyperionLabelsService,
    private mutationService: ApolloMutationService,
    private confirmationService: ConfirmationService,
    private mailDialogService: MailDialogService,
    private activeItemLabelService: ActiveItemLabelService,
    private changeDetector: ChangeDetectorRef,
    private convertNumber: ConvertNumberService,
    private filterService: FilterService,
    private moveOfferToStatusService: MoveOfferToStatusGQL,
    private splitIoService: SplitIoService,
    private fetchAtasGQL: FetchAtasGQL,
    private fetchOffersGQL: FetchOffersGQL
  ) {}

  public ngOnInit(): void {
    if (this.type === OFFER_TYPE_ATA) {
      this.setComponentForAta();
    } else {
      this.subscribeToRouteParams();
    }
    this.sort = this.sortService.getSort(this.sort);
    this.searchstateDropdown = this.stateDropdown;
    this.searchstateDropdown[0] = { value: null, label: 'Välj status' };
    this.createFilterForDates();
    this.createFilterForZignedId();

    this.splitIoService
      .getTreatment('zigned')
      .subscribe(treatment =>
        this.showFilterSentZigned.next(treatment == 'on')
      );
  }

  private setComponentForAta(): void {
    this.isAta = true;
    this.statusText.next('Skapade Äta');
    this.getLabels();
    this.getAtas();
    this.offerVariable = { id: -3, type: 'ata' };
  }

  private subscribeToRouteParams(): void {
    this.routeSub = this.activeRoute.params.subscribe(routeParam => {
      if (this.offerTable) {
        this.offerTable.reset();
      }
      this.sort = this.sortService.getSort(this.sort);

      this.offerTable.sortOrder = this.sort.ascDesc;
      this.offerTable.sortField = this.sort.attribute;

      this.status = Number(this.activeRoute.snapshot.url[1].path);

      this.getLabels();
      this.globalSearchString = null;
      if (this.querySub) {
        this.querySub.unsubscribe();
      }
      this.loadingAsync.next(true);
      this.firstDataPullDone = false;
      this.fetchData();
      this.statusText.next(this.setStatusText[this.status] + ' offerter');
    });
  }

  public getLabels(): void {
    this.labelsService.getLabels(this.labelModels).subscribe(data => {
      this.labels = data.Offer;
      const possibleHeaders = [
        {
          field: 'select',
          header: 'select',
          display: true,
          width: '4rem',
          align: 'center',
        },
        {
          field: 'trueId',
          header: data.Offer.trueId,
          display: true,
          width: '5rem',
        },
        { field: 'created', header: 'Skapad', display: true, width: '7rem' },
        { field: 'clientString', header: 'Kund', display: true },
        { field: 'concerning', header: data.Offer.concerning, display: true },
        {
          field: 'invoicedAmount',
          header: 'Fakturerat',
          display: true,
          showOnlyInATA: true,
          width: '12rem',
        },
        {
          field: 'sum',
          header: 'Summa',
          display: true,
          showOnlyInATA: true,
        },
        {
          field: 'state',
          header: data.Offer.state,
          display: this.status !== 0 ? true : false,
          width: '12.5rem',
        },
        {
          field: 'menu',
          header: '',
          display: true,
          width: '8rem',
          align: 'right',
        },
      ];
      this.offerTableColumns = possibleHeaders
        .filter(el => el.display)
        .filter(el => !el.showOnlyInATA);

      if (this.type === 'ata') {
        this.offerTableColumns = possibleHeaders.filter(
          el => el.field !== 'clientString'
        );
      }
    });
  }

  public changeSort(event): void {
    this.sortService.setSort(event, this.sort);
  }

  public selectToggleAll(): void {
    this.isAllSelected.next(!this.isAllSelected.value);
    let data = [];
    if (this.offerTable) {
      data = this.offerTable.filteredValue
        ? [...this.offerTable.filteredValue]
        : [...this.offerTable.value];
    } else {
      data = [...this.dataSetAsync.value];
    }
    this.selectedOffers = this.isAllSelected.value ? data : [];
  }

  private fetchData(): void {
    if (this.isAta) {
      this.getAtas();
    } else {
      this.getOffers();
    }
  }

  private getOffers(): void {
    this.fetchOffersGQL
      .fetch({ status: [this.status] })
      .pipe(
        first(),
        map(r => r.data.company.offers.edges.map(e => e.node))
      )
      .subscribe(data => {
        if (this.offerTable) {
          this.offerTable.resetPageOnSort = false;
        }

        this.updateOfferList(data);

        this.loadingAsync.next(false);
        this.setSelectedOffersDropdown(+this.status);
        if (!this.firstDataPullDone) {
          this.setRowsToOpenId();
          this.firstDataPullDone = true;
        }
        this.changeDetector.markForCheck();
      });
  }

  private updateOfferList(offers: OfferInformationFragment[]): void {
    const dataToSet = offers.map(obj => this.setClientString(obj));
    dataToSet.forEach(
      offer =>
        (this.optionsMenusMap[offer.id] = this.generateOptionsMenu(offer))
    );

    this.dataSetAsync.next(
      dataToSet.map(o => ({ ...o, trueId: Number(o.trueId) }))
    );
  }

  private getAtas(): void {
    this.fetchAtasGQL
      .fetch({ projectId: this.projectInfo['id'] })
      .pipe(
        first(),
        map(res => res.data.project.atas.edges.map(e => e.node))
      )
      .subscribe(data => {
        this.dataSetAsync.next(
          data.map(o => ({ ...o, trueId: Number(o.trueId) } as Offer))
        );
        this.loadingAsync.next(false);
        this.setSelectedOffersDropdown();
        this.changeDetector.markForCheck();
      });
  }

  private setRowsToOpenId(): void {
    this.activeRoute.queryParams.pipe(first()).subscribe(params => {
      this.openRowInTable(params.id, true);
      this.removeQueryParam(params.id);
    });
  }

  private newAtaCreated(): void {
    this.fetchData();
    this.updateProjectAtaNavCount.emit();
  }

  public toggleCreateAtaDialog(): void {
    this.dialogService.layout = 'wide';
    this.dialogService.data = {
      offerMode: 'create',
      projectInfo: this.projectInfo,
      offerVariable: this.offerVariable,
    };
    this.dialogService
      .openComponent(SingleOfferComponent)
      .onClose.subscribe(res => {
        if (res) {
          this.actionsFromChildComponents(res);
        }
      });
  }

  private setClientString(object): any {
    const obn = object.clientContact?.orderBuisnessName;
    const n = object.clientContact?.name;
    return {
      ...object,
      clientString: (n ? n : '') + (n && obn ? ', ' : '') + (obn ? obn : ''),
    };
  }

  public actionsFromChildComponents(action: any): void {
    const isAta = this.type === OFFER_TYPE_ATA;
    switch (action['name']) {
      case 'copiedOffer':
        this.handleCopiedOffer(action['value']);
        break;
      case 'newAtaCreated':
        if (isAta) {
          this.newAtaCreated();
        }
        break;
      case 'ataDeleted':
        if (isAta) {
          this.updateProjectAtaNavCount.emit(true);
        }
        break;
      case 'reload':
        this.fetchData();
        break;
      default:
        console.warn(action + ' Not available');
    }
  }

  private handleCopiedOffer(data: OfferInformationFragment): void {
    if (!this.projectInfo && +this.status === 2) {
      location.href =
        this.globalService.getAppUrlPrefix() + '/offer/index/1?id=' + data.id;
    } else {
      const dataSet = this.dataSetAsync.value;

      dataSet.push({ ...data, trueId: Number(data.trueId) } as Offer);

      const newDataSet = [...dataSet];

      this.updateOfferList(newDataSet);

      if (this.type === OFFER_TYPE_ATA) {
        this.updateProjectAtaNavCount.emit(true);
      }

      setTimeout(() => {
        this.openRowInTable(data.id, true);
      }, 300);
    }
  }

  public stateChange(eventParam, rowData): void {
    const dataToMutation = {
      id: Number(rowData['id']),
      state: eventParam,
    };
    this.moveOfferToStatusService
      .mutate(dataToMutation)
      .pipe(first())
      .subscribe(result => {
        this.mutationService.displayMutationStatus(result);
        this.fetchData();
      });
  }

  public invoicedAmountChange(rowData: any): void {
    this.actionUpdate({
      id: rowData.id,
      invoicedAmount: this.convertNumber.fromString(rowData.invoicedAmount),
    });
  }

  private setSelectedOffersDropdown(statusParam = null): void {
    const seletedOptionsValue =
      this.type === OFFER_TYPE_ATA ? 'ÄTA' : 'offerter';
    const dropdown = [
      {
        value: null,
        label: 'Välj åtgärd för valda ' + seletedOptionsValue + '...',
      },
    ];

    if (+statusParam === ACTIVE_ROUTE['archievedOffers']) {
      dropdown.push({ value: 'sendToOngoing', label: 'Skicka till skapade' });
    } else {
      if (this.type !== OFFER_TYPE_ATA) {
        dropdown.push({ value: 'archiveSelected', label: 'Arkivera' });
      }
    }

    const newDropdown = [...dropdown];
    this.handleSelectedOffersDropdown = newDropdown;
  }

  public handleSelectedOffers(eventValue): void {
    const value = '' + eventValue;
    if (value === 'archiveSelected') {
      this.toggleConfirmDialog(true);
    } else if (value === 'sendToOngoing') {
      this.toggleConfirmDialog(true, true);
    }
  }

  private toggleConfirmDialog(
    showChangeOfferStatusDialog: boolean,
    moveToOnGoing = false
  ): void {
    this.showConfirmation = showChangeOfferStatusDialog;

    if (moveToOnGoing) {
      this.moveOfferToOngoing = moveToOnGoing;
    }

    if (!this.showConfirmation) {
      this.moveOfferToOngoing = false;
    }
    this.changeOfferStatusDialog();
  }

  private changeOfferStatusDialog(): void {
    this.confirmationService.confirm({
      message: this.moveOfferToOngoing
        ? 'Är du säker på att du vill flytta markerade offerter till skapade?'
        : 'Är du säker på att du vill arkivera markerade offerter?',
      header: 'Bekräfta val',
      icon: 'fa fa-question-circle',
      accept: () => {
        this.moveOfferToOngoing
          ? this.handleDialogAction('sendToOngoing')
          : this.handleDialogAction('archiveSelected');
      },
    });
  }

  handleDialogAction(action: string) {
    action === 'archiveSelected' && this.updateSelected(2);

    action === 'sendToOngoing' && this.updateSelected(1);

    this.showConfirmation = false;
    this.moveOfferToOngoing = false;
  }

  public openOfferDataDialog() {
    this.dialogService.openComponent(OfferDataComponent);
  }

  updateSelected(statusParam) {
    const selectedOffers = this.selectedOffers;
    for (const index in selectedOffers) {
      const offer = selectedOffers[index];

      const dataToMutation = {
        id: +offer['id'],
        status: statusParam,
      };
      this.actionUpdate(dataToMutation, true);
    }

    this.selectedOffers = [];
  }

  public removeQueryParam(id: string | number): void {
    this.activeItemLabelService.unsetActiveItem();
    this.activeRoute.queryParams.pipe(first()).subscribe(params => {
      const paramIds = params.id?.split(',');

      if (paramIds) {
        const newParamIds = paramIds.filter(
          (paramId: string | number) => paramId !== id
        );

        if (newParamIds.length) {
          this.router.navigate([], {
            relativeTo: this.activeRoute,
            replaceUrl: true,
            queryParams: {
              id: newParamIds.join(','),
            },
            queryParamsHandling: 'merge',
          });
        } else {
          this.router.navigate([], {
            relativeTo: this.activeRoute,
            replaceUrl: true,
          });
        }
      }
    });
  }

  public openRowInTable(id: string, scrollIntoView = false): void {
    this.dataSetAsync.pipe(debounceTime(100), first()).subscribe(dataSet => {
      dataSet = dataSet as any[];

      if (this.offerTable === undefined) {
        return;
      }

      const rowToExpand = dataSet.find(
        offer => Number(offer.id) === Number(id)
      );

      if (!rowToExpand) {
        return;
      }

      this.activeItemLabelService.setActiveItem({
        id: rowToExpand.trueId ?? 'Utkast',
        name: rowToExpand.concerning,
      });

      const offerId = rowToExpand.id;
      if (!this.offerTable.expandedRowKeys[offerId]) {
        this.offerTable.toggleRow(rowToExpand);
      }
      if (scrollIntoView) {
        this.scrollShouldHappen = true;
        this.scrollElementIntoView(id);
      }
    });
  }

  private scrollElementIntoView(idValue: string): void {
    this.goToPageOf(idValue);
    const el: Element = document.getElementById(idValue);
    if (!el) {
      return;
    }
    const target = el.parentElement.parentElement;
    target.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
    target.classList.add('created-row');
  }

  private goToPageOf(idValue: string): void {
    if (this.offerTable.filteredValue) {
      return;
    }
    const rows = this.offerTable.rows;
    const index = this.dataSetAsync
      .getValue()
      .findIndex(offer => Number(offer.id) === Number(idValue));
    if (index === -1) {
      return;
    }
    const newFirst = Math.floor(index / rows) * rows;
    if (newFirst === this.first) {
      return;
    }
    this.first = newFirst;
    this.changeDetector.detectChanges();
  }

  public actionUpdate(dataToMutation, deleteSetFromStore = false): void {
    const crudType = 'update';
    const executeMutationSub = this.mutationService
      .constructQueryAndExecuteMutation(
        'offer',
        crudType,
        false,
        dataToMutation
      )
      .subscribe(
        executedData => {
          this.mutationService.displayMutationStatus(executedData);
          executeMutationSub.unsubscribe();

          if (
            executedData.mutationSucceededAllArguments &&
            deleteSetFromStore
          ) {
            this.fetchData();
          }
        },
        err => {
          console.log(err);
        }
      );
  }

  public formatDate(dateString: string): string {
    return moment(dateString).format('YYYY-MM-DD');
  }

  public formatTime(dateString: string): string {
    return moment(dateString).format('HH:mm');
  }

  public generateOptionsMenu(offer: any): MenuItem[] {
    const menuItems = [];
    if (this.status === 2) {
      menuItems.push({
        label: 'Återställ',
        icon: 'pi pi-inbox',
        command: _ => {
          this.selectedOffers.push(offer);
          this.handleSelectedOffers('sendToOngoing');
        },
      });
    } else {
      menuItems.push({
        label: 'Skicka',
        icon: 'pi pi-envelope',
        command: _ => {
          this.showMailModal(offer);
        },
      });
      menuItems.push({
        label: 'Arkivera',
        icon: 'pi pi-inbox',
        command: _ => {
          this.selectedOffers.push(offer);
          this.handleSelectedOffers('archiveSelected');
        },
      });
    }
    if (offer.sended === 0) {
      menuItems.push({
        label: 'Radera',
        icon: 'pi pi-trash',
        command: _ => {
          this.deleteDialog(offer);
        },
      });
    }

    return menuItems;
  }

  private showMailModal(offer) {
    this.mailDialogService.openMailDialog({
      offerId: offer['id'],
      mail: offer['clientContact']['mail'],
    });
  }

  private deleteDialog(offer) {
    this.confirmationService.confirm({
      message: 'Radera offert?',
      header: 'Radera',
      icon: 'pi pi-question-circle',
      accept: () => {
        this.actionDelete(offer);
      },
    });
  }

  private actionDelete(offer): void {
    const dataToMutation = { id: Number(offer.id) };
    this.removeQueryParam(offer.id);

    const executeMutationSub = this.mutationService
      .constructQueryAndExecuteMutation(
        'offer',
        'delete',
        false,
        dataToMutation
      )
      .subscribe(
        data => {
          if (data.mutationSucceededAllArguments) {
            executeMutationSub.unsubscribe();
          }
          this.fetchData();
        },
        err => {
          console.log(err);
        }
      );
  }
  public filterDates(table: Table): void {
    table.filter(
      { startDate: this.startDate, endDate: this.endDate },
      'created',
      'between'
    );
  }

  private createFilterForDates(): void {
    this.filterService.register(
      'between',
      (value: string, filter: { startDate: string; endDate: string }) => {
        if (!filter.startDate && !filter.endDate) {
          return true;
        }

        // value might include time after date, so we only compare the date
        value = value.split(' ')[0];

        if (!filter.startDate) {
          return this.filterService.filters['lte'](value, filter.endDate);
        }
        if (!filter.endDate) {
          return this.filterService.filters['gte'](value, filter.startDate);
        }
        return (
          this.filterService.filters['gte'](value, filter.startDate) &&
          this.filterService.filters['lte'](value, filter.endDate)
        );
      }
    );
  }
  private createFilterForZignedId(): void {
    this.filterService.register(
      'zignedIdFilter',
      (value: string, filter: 0 | 1 | null) => {
        if (filter === null) {
          return true;
        }
        if (filter === 0) {
          return value === null;
        } else {
          return value !== null;
        }
      }
    );
  }

  public filterBySentForSigning(event: 0 | 1 | null): void {
    this.offerTable.filter(event, 'zignedId', 'zignedIdFilter');
  }

  public ngOnDestroy(): void {
    this.atasSub?.unsubscribe();
    this.querySub?.unsubscribe();
    this.routeSub?.unsubscribe();
  }

  public filterByUsers(event: MultiSelectUserFragment[]): void {
    const ids = event.map(u => u.id);
    this.offerTable.filter(ids, 'user.id', 'in');
  }

  public actionExportExcel(): void {
    const worksheet = utils.json_to_sheet(this.getFormattedDataForExcel());

    const range = utils.decode_range(worksheet['!ref']);
    worksheet['!cols'] = [5, 20, 20, 15, 20, 10, 20, 18].map(width => {
      return { wch: width };
    });

    utils.sheet_set_array_formula(
      worksheet,
      'D' + (range.e.r + 1),
      'SUM(INDIRECT(ADDRESS(1,COLUMN())&":"&ADDRESS(ROW()-1,COLUMN())))'
    );
    utils.sheet_set_array_formula(
      worksheet,
      'E' + (range.e.r + 1),
      'SUM(INDIRECT(ADDRESS(1,COLUMN())&":"&ADDRESS(ROW()-1,COLUMN())))'
    );

    const fmt = '#,##0.00';
    for (let j = 0; j <= range.e.c; ++j) {
      const ref = utils.encode_cell({ r: 0, c: j });
      if (!worksheet[ref]) continue;
      worksheet[ref].s = {
        font: {
          bold: true,
        },
      };
    }

    for (let i = 1; i <= range.e.r; ++i) {
      for (let j = 1; j <= range.e.c; ++j) {
        const ref = utils.encode_cell({ r: i, c: j });
        if (!worksheet[ref]) continue;
        if (worksheet[ref].t != 'n') continue;
        worksheet[ref].z = fmt;
      }
    }

    const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    const excelBuffer = xlsx.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });

    this.saveAsExcelFile(
      excelBuffer,
      `Ätaliggare ${this.projectInfo['trueId']}`
    );
  }

  private saveAsExcelFile(buffer, fileName: string): void {
    const EXCEL_TYPE =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    FileSaver.saveAs(
      data,
      fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION
    );
  }

  private getFormattedDataForExcel(): {
    'Nr. ': string;
    'Märkning': string;
    'Skickad Datum': string;
    'Äta belopp': number;
    'Fakturerat belopp': number;
    'Status': string;
    'Status Datum': string;
    'Digitalt signerad': string;
  }[] {
    const values = this.dataSetAsync
      .getValue()
      .map((o: OfferInformationFragment) => ({
        'Nr. ': o.trueId,
        'Märkning': o.concerning,
        'Skickad Datum': o.sentSigningEvent?.created ?? 'Ej skickad',
        'Äta belopp': Number(o.sumWork) + Number(o.sumMaterial),
        'Fakturerat belopp': Number(o.invoicedAmount),
        'Status': this.stateDropdown
          .filter(dd => dd.value === o.state)
          .map(dd => dd.label)
          .at(0),
        'Status Datum': o.latestSigningEvent?.created ?? o.created,
        'Digitalt signerad': o.isDigitallySigned === '1' ? 'Ja' : 'Nej',
      }));

    const sum = {
      'Nr. ': '',
      'Märkning': 'Summa',
      'Skickad Datum': '',
      'Äta belopp': 0,
      'Fakturerat belopp': 0,
      'Status': '',
      'Status Datum': '',
      'Digitalt signerad': '',
    };

    return [...values, sum];
  }
}
