import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { ApolloMutationService } from '../../../shared/apollo/index';
import { FormHandlerService } from '../../../shared/forms';
import { GlobalService } from '../../../shared/global/index';

import { ConfirmationService } from 'primeng/api';
import {
  AccountingPlanAccount,
  AccountingPlanAccountsDropdown,
} from 'app/settings/company/services/accounting-plan.types';
import { AccountingPlanService } from 'app/settings/company/services/accounting-plan.service';

@Component({
  selector: 'companyUserCostType-edit',
  templateUrl: 'company-user-cost-type-edit.component.html',
  providers: [FormHandlerService],
})
export class EditUserCostTypeComponent implements OnInit {
  @Input() public userCostType;
  @Input() public hasAccountingPlanFunction: boolean;
  @Input() public accountingPlanAccounts: AccountingPlanAccount[] = [];

  componentMainForm: FormGroup;
  dataModel = 'companyUserCostType';
  dataModelCapitalized = 'CompanyUserCostType';
  formSettings;
  formFields = {
    model: this.dataModelCapitalized,
    attributes: {
      id: '',
      name: '',
      cost: '',
      internalCost: '',
      accountingPlanAccount: null,
      reverseVatAccountingPlanAccount: null,
    },
  };
  buttons;
  componentPrefix;
  public accountingPlanAccountsDropdownParams: AccountingPlanAccountsDropdown;

  constructor(
    private mutationService: ApolloMutationService,
    private formHandler: FormHandlerService,
    private confirmationService: ConfirmationService,
    private globalService: GlobalService,
    private accountingPlanService: AccountingPlanService
  ) {
    const buttonObject = {
      delete: {
        model: this.dataModel,
      },
      update: {
        model: this.dataModel,
      },
    };

    this.buttons = this.formHandler.getButtonValues(buttonObject);
    this.formSettings = this.globalService.getFormSettings();
  }

  ngOnInit() {
    this.initForm();
    this.componentPrefix = this.globalService.generateComponentPrefix();

    this.accountingPlanAccountsDropdownParams =
      this.accountingPlanService.getAccountsDropdownParams(
        this.accountingPlanAccounts
      );
  }

  private initForm() {
    this.componentMainForm = this.formHandler.groupedFormSimple(
      this.formFields
    );
    this.formHandler.groupSetLabelsRules(
      this.formFields,
      this.componentMainForm,
      this.userCostType
    );
  }

  actionUpdate(shouldActivate = false) {
    const crudType = 'update';
    const dataToMutation = this.componentMainForm.value;
    dataToMutation.id = +dataToMutation.id;

    dataToMutation.accountingPlanAccountId =
      dataToMutation.accountingPlanAccount;
    delete dataToMutation.accountingPlanAccount;

    dataToMutation.reverseVatAccountingPlanAccountId =
      dataToMutation.reverseVatAccountingPlanAccount;
    delete dataToMutation.reverseVatAccountingPlanAccount;

    if (shouldActivate) {
      dataToMutation.active = 1;
    }

    if (this.formHandler.formValid([this.componentMainForm])) {
      this.buttons = this.formHandler.lockButtons(this.buttons);
      this.formHandler.setLoadingClasses('start');
      const refetchArr = [{ name: this.dataModel, variables: null }];
      const executeMutationSub = this.mutationService
        .constructQueryAndExecuteMutation(
          this.dataModel,
          crudType,
          false,
          dataToMutation,
          null,
          refetchArr
        )
        .subscribe(
          executedData => {
            this.mutationService.displayMutationStatus(executedData);
            this.formHandler.showServerErrorsOnAttributes(executedData, [
              {
                form: this.componentMainForm,
                argument: crudType + this.dataModelCapitalized,
              },
            ]);

            let setLoadingClassesInput = 'error';
            if (executedData.mutationSucceededAllArguments) {
              setLoadingClassesInput = 'success';
            }

            this.formHandler.setLoadingClasses(setLoadingClassesInput);

            setTimeout(() => {
              this.formHandler.setLoadingClasses();
              this.buttons = this.formHandler.unlockButtons(this.buttons);
            }, 1000);

            executeMutationSub.unsubscribe();
          },
          err => {
            console.warn(err);
          }
        );
    }
  }

  actionActivate() {
    this.actionUpdate(true);
  }

  confirmActionDelete() {
    this.confirmationService.confirm({
      message: 'Är du säker på att du vill ta bort yrkestypen?',
      header: 'Bekräfta val',
      icon: 'fa fa-trash',
      accept: () => {
        this.actionDelete();
      },
      reject: () => {},
    });
  }

  private actionDelete() {
    this.formHandler.setLoadingClasses('start');
    this.buttons = this.formHandler.lockButtons(this.buttons, true);
    const crudType = 'update';
    const dataToMutation = {
      id: Number(this.userCostType['id']),
      active: 0,
    };
    const refetchArr = [{ name: this.dataModel, variables: null }];
    const executeMutationSub = this.mutationService
      .constructQueryAndExecuteMutation(
        this.dataModel,
        crudType,
        false,
        dataToMutation,
        null,
        refetchArr
      )
      .subscribe(
        data => {
          this.mutationService.displayMutationStatus(data);
          this.formHandler.showServerErrorsOnAttributes(data, [
            {
              form: this.componentMainForm,
              argument: crudType + this.dataModelCapitalized,
            },
          ]);
          if (data.mutationSucceededAllArguments) {
            this.formHandler.setLoadingClasses('success');
          } else {
            this.formHandler.setLoadingClasses('error');
          }
          this.buttons = this.formHandler.unlockButtons(this.buttons, true);
          executeMutationSub.unsubscribe();
        },
        err => {
          console.warn(err);
        }
      );
  }
}
