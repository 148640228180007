import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProjectCountService } from 'app/project/project-count-service/project-count-service.service';
import { MailDialogService } from 'app/shared/dialogs/mail/mail-dialog.service';
import { UserTodoRelation } from 'generated/types';
import { BehaviorSubject, first } from 'rxjs';
import {
  GetDaysGQL,
  GetTodosGQL,
  GetUsersWithMailGQL,
  UserFragment,
} from './graphql/project-todo.generated';

export interface Todo {
  id?: string | number;
  done?: boolean;
  description?: string;
  orderNr?: number;
  startDate?: string;
  endDate?: string;
  estimatedTime?: number;
  users?: UserFragment[];
  userIds?: string[];
  topic?: {
    id?: string;
    name?: string;
  };
  timeUsed?: number;
  defaultForAllProjects?: boolean;
  relations?: UserTodoRelation[];
}

export interface Topic {
  id?: string;
  done?: boolean;
  description?: string;
  startDate?: string;
  endDate?: string;
  estimatedTime?: number;
  todos?: Todo[];
  timeUsed?: number;
}

@Component({
  selector: 'app-project-todo',
  templateUrl: './project-todo.component.html',
  styleUrls: ['./project-todo.component.scss'],
})
export class ProjectTodoComponent implements OnInit {
  public showCreateTodo = false;

  public todos: BehaviorSubject<Todo[]> = new BehaviorSubject([]);
  public todoTopics: BehaviorSubject<Topic[]> = new BehaviorSubject([]);

  public editTodoId: BehaviorSubject<string> = new BehaviorSubject('');
  public isEdit: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public projectId: number;
  public outerColumns = [
    {
      header: 'Klart',
      width: '6.5rem',
      field: 'done',
    },
    {
      header: 'Rubrik',
      width: 'auto',
      field: 'description',
    },
    {
      header: 'Startdatum',
      width: '8.5rem',
      field: 'startDate',
    },
    {
      header: 'Slutdatum',
      width: '8.5rem',
      field: 'endDate',
    },
    {
      header: 'Estimat',
      width: '7.5rem',
      field: 'estimatedTime',
    },
    {
      header: 'Utslag',
      width: '6.5rem',
      field: 'timeUsed',
    },
  ];
  public sendMailItems: { label: string; command: () => void }[];

  constructor(
    private route: ActivatedRoute,
    private getTodosService: GetTodosGQL,
    private getDays: GetDaysGQL,
    private mailDialogService: MailDialogService,
    private projectCountService: ProjectCountService,
    private getUsersService: GetUsersWithMailGQL
  ) {}

  public ngOnInit(): void {
    this.route.parent.params.subscribe(params => {
      this.projectId = params.id;
      this.getTodos();
    });
    this.createUserDropdown();
  }

  public getTodos(closeCreate = false): void {
    this.getDays
      .fetch({ projectId: this.projectId })
      .pipe(first())
      .subscribe(daysRes => {
        this.projectCountService.count(this.projectId);
        const days = daysRes.data.project.days.edges.map(d => d.node);
        this.getTodosService
          .fetch({ projectId: this.projectId })
          .pipe(first())
          .subscribe(res => {
            const todos: Todo[] = res.data.project.todos.edges
              .map(n => ({ ...n.node }))
              .map(t => ({
                ...t,
                topic: { name: t.topic?.Name, id: t.topic?.id },
                done: Boolean(t.done),
                users: t.usersTodoRelation.edges.map(n => n.node.user),
                userIds: t.usersTodoRelation.edges.map(n => n.node.user.id),
                timeUsed: days
                  .filter(d => d.todoRelation?.todo_id === Number(t.id))
                  .map(d => d.hours)
                  .reduce((acc, h) => acc + h, 0),
                relations: t.usersTodoRelation.edges.map(e => e.node),
              }));

            const todoTopics: Topic[] = this.topicsFromTodos(todos);
            this.todoTopics.next(todoTopics);
            this.todos.next(todos);
          });
      });
    if (closeCreate) {
      this.showCreateTodo = false;
    }
  }

  private topicsFromTodos(todos: Todo[]): Topic[] {
    return todos
      .map(t => t.topic)
      .filter((x, i, a) => a.map(tt => tt.id).indexOf(x.id) === i)
      .map(tt => {
        const localTodos = todos.filter(t => t.topic.id === tt.id);
        return {
          id: tt.id,
          description: tt.name,
          todos: localTodos,
          done: localTodos.every(lt => lt.done),
          estimatedTime: localTodos
            .map(lt => lt.estimatedTime)
            .reduce((acc, et) => acc + et),
          startDate: localTodos.map(lt => lt.startDate).sort()[0],
          endDate: localTodos
            .map(lt => lt.endDate)
            .sort()
            .reverse()[0],
          timeUsed: localTodos
            .map(lt => lt.timeUsed)
            .reduce((acc, h) => acc + h),
        };
      });
  }

  private createUserDropdown(): void {
    this.getUsersService
      .fetch()
      .pipe(first())
      .subscribe(res => {
        const users = res.data.company.users.edges.map(e => e.node);

        this.sendMailItems = users.map(u => {
          return {
            label: `${u.firstName} ${u.lastName}`,
            command: () => {
              this.showMailModule(u.email);
            },
          };
        });
      });
  }

  public createTodo(): void {
    this.isEdit.next(false);
    this.showCreateTodo = true;
  }

  public editTodo(id: string): void {
    this.isEdit.next(true);
    this.editTodoId.next(id);
    this.showCreateTodo = true;
  }

  public showPdf(): void {
    const url = '/project/PrintTodo/' + this.projectId;
    window.open(url);
  }

  public showMailModule(email = null): void {
    const files = [
      {
        path: `RENDER:printTodo:${this.projectId}:{}`,
        file: 'Arbetsmoment',
      },
    ];

    this.mailDialogService.openMailDialog({
      email,
      projectId: Number(this.projectId),
      files,
    });
  }

  public onUpdate(event = false): void {
    this.getTodos(event);
  }
}
