import * as Types from '../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type ProjectCountsQueryVariables = Types.Exact<{
  projectId?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type ProjectCountsQuery = { __typename?: 'HyperionSchemaQuery', project?: { __typename?: 'Project', todoCount?: number | null, commentCount?: number | null, pictureCount?: number | null, documentCount?: number | null, costumerRelationsToProject?: { __typename?: 'CostumerRelationToProjectConnection', totalCount: number } | null, rots?: { __typename?: 'ProjectRotConnection', totalCount: number } | null, supplierInvociesBySource_PreProjectTypeHyperion?: { __typename?: 'suppliersDevConnection', edges?: Array<{ __typename?: 'suppliersDevEdge', node?: { __typename?: 'suppliersDev', invoices?: { __typename?: 'InvoiceConnection', totalCount: number } | null } | null } | null> | null } | null, installments?: { __typename?: 'ProjectInstallmentConnection', totalCount: number } | null, atas?: { __typename?: 'OfferConnection', totalCount: number } | null, attendanceReports?: { __typename?: 'AttendanceReportConnection', totalCount: number, edges?: Array<{ __typename?: 'AttendanceReportEdge', node?: { __typename?: 'AttendanceReport', startOrStop?: string | null, parentId?: number | null } | null } | null> | null } | null } | null };

export const ProjectCountsDocument = gql`
    query projectCounts($projectId: Int) {
  project(id: $projectId) {
    todoCount
    commentCount
    pictureCount
    documentCount
    costumerRelationsToProject {
      totalCount
    }
    rots {
      totalCount
    }
    supplierInvociesBySource_PreProjectTypeHyperion {
      edges {
        node {
          invoices {
            totalCount
          }
        }
      }
    }
    installments {
      totalCount
    }
    atas {
      totalCount
    }
    attendanceReports {
      edges {
        node {
          startOrStop
          parentId
        }
      }
      totalCount
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ProjectCountsGQL extends Apollo.Query<ProjectCountsQuery, ProjectCountsQueryVariables> {
    document = ProjectCountsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }